/**
 * General utilities without external dependencies.
 */

/**
 * Scroll to the top of the window.
 */
export const scrollToTop = (): void => {
  window.scrollTo(0, 0);
};

/**
 * Returns the next item in an array, looping around to grab the first index if the
 * current item is at the end.
 *
 * Note: The items should be unique.
 *
 * @param currentItem The current item.
 * @param items The list of items.
 */
export const getNextItem = (currentItem: string, items: string[]): string | undefined => {
  const index = items.indexOf(currentItem);
  if (index < 0) {
    // Item not found in list, return the first item.
    return items[0];
  }
  if (items.length === 1) {
    // This is the only item in the list, return nothing.
    return undefined;
  }
  if (index === items.length - 1) {
    // Item is the last item, return the first item.
    return items[0];
  }
  // Return the next item in the list.
  return items[index + 1];
};

/**
 * Returns the previous item in an array, looping around to grab the last index if the
 * current item is at the front.
 *
 * Note: The items should be unique.
 */
export const getPreviousItem = (currentItem: string, items: string[]): string | undefined => {
  const index = items.indexOf(currentItem);
  if (index < 0) {
    // Not found, return first item.
    return items[0];
  }
  if (items.length === 1) {
    // Only item in the list, return nothing.
    return undefined;
  }
  if (index === 0) {
    // First item in the list, return the final.
    return items[items.length - 1];
  }
  // Return the previous item.
  return items[index - 1];
};
