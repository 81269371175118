import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SubscriptionState } from '../../api/types';
import { AppState } from '../../store';
import SharedNavbar from '../navigation/SharedNavbar';
import SubscriptionListItem from './SubscriptionListItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
`;

const TableHeaderItem = styled.p`
  color: #b1b2b3;
  font-size: 17px;
  font-weight: 500;
`;

const SubscriptionsList = styled.div`
  margin-top: 4px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
`;

const LeftHeader = styled.div``;

const RightHeader = styled.div`
  margin-left: auto;
  margin-right: 24px;
  width: 100px;
`;

const Subscriptions: React.FC = () => {
  const subscribed = useSelector<AppState, SubscriptionState[]>((state) =>
    Object.values(state.newsletters.subscriptions).reverse()
  );

  return (
    <>
      <SharedNavbar title="My Subscriptions" />

      <Container>
        <TableHeader>
          <LeftHeader>
            <TableHeaderItem>Newsletter</TableHeaderItem>
          </LeftHeader>
          <RightHeader>
            <TableHeaderItem>Subscribed</TableHeaderItem>
          </RightHeader>
        </TableHeader>
        <SubscriptionsList>
          {subscribed.map(({ subscription }) => {
            return <SubscriptionListItem key={subscription.id} subscription={subscription} />;
          })}
        </SubscriptionsList>
      </Container>
    </>
  );
};

export default Subscriptions;
