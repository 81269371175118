import cheerio from 'cheerio';
import { useEffect, useState } from 'react';

import { sanitizeWhitespace } from '../util/html';

const useImages = (article: string): string[] => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    const $ = cheerio.load(sanitizeWhitespace(article));

    // Look for images matching the Substack classes.
    const images: string[] = [];
    $('div.post')
      .find('.image-wrapper')
      .each((idx, image) => {
        const url = $(image).find('img').first().attr('src');
        if (url) {
          images.push(url);
        }
      });

    setImageUrls(images);
  }, [article, setImageUrls]);

  return imageUrls;
};

export default useImages;
