import React, { memo, ReactNode, useCallback, useState } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

interface ModalProps {
  className?: string; // Applied to content.
  children?: ReactNode;
  desktopHeight?: string;
  desktopWidth?: string;
  onDismiss: () => void;
  showing: boolean;
}

const Modal: React.FC<ModalProps> = ({ className, children, desktopHeight, desktopWidth, onDismiss, showing }) => {
  const [wasOpen, setWasOpen] = useState(false);

  const afterOpen = useCallback(() => {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  }, []);

  const afterClose = useCallback(() => {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  }, []);

  const width = `${desktopWidth ? desktopWidth : 'lg:max-w-3xl'}`;
  const height = `${desktopHeight ? desktopHeight : 'lg:max-h-96'}`;

  return (
    <ReactModal
      isOpen={showing}
      onRequestClose={onDismiss}
      className={{
        base: `popup-content--base absolute left-0 lg:left-1/2 top-0 lg:top-1/2 p-0 border-0 w-full h-full bg-white lg:shadow-2xl focus:outline-none duration-300 ${width} ${height} ${className}`,
        afterOpen: wasOpen ? 'popup-content--after-open' : '',
        beforeClose: 'popup-content--before-close',
      }}
      overlayClassName={{
        base: 'popup-overlay--base',
        afterOpen: wasOpen ? 'popup-overlay--after-open' : '',
        beforeClose: 'popup-overlay--before-close',
      }}
      closeTimeoutMS={300}
      onAfterOpen={afterOpen}
      onAfterClose={afterClose}
    >
      {children}
    </ReactModal>
  );
};

export default memo(Modal);
