import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ErrorMessage from '../components/ui/ErrorMessage';
import astronaut from '../img/Astronaut.png';
import logoIcon from '../img/flowbox-logo.svg';
import { performSignIn } from '../state/users';
import { AppState } from '../store';

/**
 * The main sign in view.
 */
const SignIn: React.FC = () => {
  const dispatch = useDispatch();

  const { profile, loading, error } = useSelector((state: AppState) => ({
    profile: state.users.profile,
    ...state.users.signIn,
  }));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Redirect to the home view once we have a user.
    if (profile) {
      console.log('Attempting to redirect from sign in');
      dispatch(push('/'));
    }
  }, [dispatch, profile]);

  const onEmailChange = useCallback(
    ({ target }) => {
      setEmail(target.value);
    },
    [setEmail]
  );

  const onPasswordChange = useCallback(
    ({ target }) => {
      setPassword(target.value);
    },
    [setPassword]
  );

  const onSubmit = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      if (email && password) {
        dispatch(performSignIn(email, password));
      }
    },
    [email, password, dispatch]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Attempt to submit if enter key.
      if (event.keyCode === 13) {
        event.preventDefault();
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <div className="flex items-center justify-center h-screen mx-auto max-w-5xl">
      <div className="absolute top-3 left-5">
        <img className="h-12" src={logoIcon} alt="logo" />
      </div>

      <div className="hidden lg:flex">
        <img className="w-80" src={astronaut} alt="astronaut" />
      </div>

      <div className="mx-8 flex flex-col w-80">
        <h1 className="text-3xl font-medium">Welcome back</h1>
        <h3 className="text-lg font-medium mt-5">Sign in</h3>

        <input
          className="focus:border-light-blue-500 bg-gray-50 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full mt-2 max-w-xs text-base text-black placeholder-gray-400 border border-gray-200 rounded-md py-2 px-3"
          type="text"
          autoComplete="email"
          placeholder="Email"
          onChange={onEmailChange}
          onKeyDown={onKeyDown}
          value={email}
        />
        <input
          className="focus:border-light-blue-500 bg-gray-50 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full mt-2 max-w-xs text-base text-black placeholder-gray-400 border border-gray-200 rounded-md py-2 px-3"
          type="password"
          placeholder="Password"
          onChange={onPasswordChange}
          onKeyDown={onKeyDown}
          value={password}
        />

        {error && <ErrorMessage className="mt-5 text-center" error={error} />}

        <button
          className={`bg-pink rounded-md text-white px-8 py-2 font-semibold disabled:opacity-70 focus:outline-none ${
            error ? 'mt-3' : 'mt-8'
          }`}
          disabled={loading}
          onClick={onSubmit}
        >
          Submit
        </button>
        <p className="mt-4 text-sm text-gray-500">
          Not on Flowbox yet?{' '}
          <Link to="/signup">
            <span className="ont-medium text-pink hover:underline">Sign up here</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
