import cheerio from 'cheerio';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Article } from '../../api/types';

interface ArticleContentProps {
  article: Article;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 75%;
  padding: 32px;
`;

const Body = styled.div`
  margin-top: 24px;
`;

const ArticleContent: React.FC<ArticleContentProps> = ({ article }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Parse out the main article content with cheerio.
    const $ = cheerio.load(article.content);
    $('div.post a').each((idx, element) => {
      $(element).attr('target', '_blank');
    });
    setContent($('div.post').last().html() || article.content);
  }, [article, setContent]);

  return (
    <Container>
      <Content>
        <Body dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
    </Container>
  );
};

export default memo(ArticleContent);
