/**
 * The main Redux state comprised of the various reducer slices.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import categoryReducer from './state/categories';
import newsletterReducer from './state/newsletters';
import userReducer from './state/users';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = (history: History) =>
  combineReducers({
    categories: categoryReducer,
    newsletters: newsletterReducer,
    router: connectRouter(history),
    users: userReducer,
  });

export default createRootReducer;
