/**
 * Redux store slice related to user management.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@supabase/gotrue-js';

import { createProfileForUser, getUser, signIn, signOut, signUp } from '../api/db';
import { Inbox, Profile } from '../api/types';
import { AppThunk } from '../store';
import { fetchArticles, fetchInboxCounts, fetchSubscriptions } from './newsletters';

const AUTH_KEY = 'flowbox.auth';

interface UserState {
  initialLoadDone: boolean;
  articlesLoadDone: boolean;

  signIn: {
    loading: boolean;
    error?: string;
  };
  signUp: {
    loading: boolean;
    error?: string;
  };
  user?: User;
  profile?: Profile;

  showingForwardingModal: boolean;
}

interface UserPayload {
  user?: User;
  profile?: Profile;
}

const initialState: UserState = {
  articlesLoadDone: false,
  initialLoadDone: false,

  signIn: { loading: false },
  signUp: { loading: false },

  showingForwardingModal: false,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    initialLoadCompleted(state) {
      state.initialLoadDone = true;
    },
    sessionRestored(state, action: PayloadAction<Profile>) {
      state.initialLoadDone = true;
      state.profile = action.payload;
    },
    signInStarted(state) {
      state.signIn.loading = true;
    },
    signInSuccess(state, action: PayloadAction<UserPayload>) {
      state.signIn.loading = false;
      state.signIn.error = undefined;
      state.user = action.payload.user;
      state.profile = action.payload.profile;
    },
    signInFailed(state, action: PayloadAction<string>) {
      state.signIn.loading = false;
      state.signIn.error = action.payload;
    },
    signUpStarted(state) {
      state.signUp.loading = true;
    },
    signUpSuccess(state, action: PayloadAction<UserPayload>) {
      state.signUp.loading = false;
      state.signUp.error = undefined;
      state.user = action.payload.user;
      state.profile = action.payload.profile;
    },
    signUpFailed(state, action: PayloadAction<string>) {
      state.signUp.loading = false;
      state.signUp.error = action.payload;
    },
    signOutSuccess(state) {
      state.user = undefined;
      state.profile = undefined;
    },
    articlesLoadCompleted(state) {
      state.articlesLoadDone = true;
    },
    toggleForwardingModal(state) {
      state.showingForwardingModal = !state.showingForwardingModal;
    },
  },
});

export const {
  initialLoadCompleted,
  sessionRestored,
  signInStarted,
  signInSuccess,
  signInFailed,
  signUpStarted,
  signUpSuccess,
  signUpFailed,
  signOutSuccess,
  articlesLoadCompleted,
  toggleForwardingModal,
} = userSlice.actions;

export default userSlice.reducer;

/**
 * Thunk that attempts to authenticate a user.
 *
 * @param email The user's email address.
 * @param password The user's password.
 */
export const performSignIn = (email: string, password: string): AppThunk => async (dispatch) => {
  try {
    dispatch(signInStarted());
    const user = await signIn(email, password);
    const profile = await getUser(user.id);
    console.info('Successful sign in', email);

    // This also needs to set the auth token so that our auth wrapper detects a session.
    localStorage.setItem(AUTH_KEY, user.id);
    dispatch(signInSuccess({ user, profile }));
    if (profile) {
      dispatch(initializeSession(profile.uid));
    }
  } catch (error) {
    console.error('Error attempting to sign in', error);
    dispatch(signInFailed(error.message));
  }
};

/**
 * Thunk that attempts to sign up a user.
 *
 * @param name The user's name.
 * @param email The user's email address.
 * @param username The user's unique username.
 * @param password The user's password.
 */
export const performSignUp = (name: string, email: string, username: string, password: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(signUpStarted());
    const user = await signUp(email, password);
    const profile = await createProfileForUser(user.id, name, email, username, password);
    console.info('Successful sign up', email);

    // This also needs to set the auth token so that our auth wrapper detects a session.
    localStorage.setItem(AUTH_KEY, user.id);
    dispatch(signUpSuccess({ user, profile }));
    if (profile) {
      dispatch(initializeSession(profile.uid));
    }
  } catch (error) {
    console.error('Error attempting to sign up', error);
    dispatch(signUpFailed(error.message));
  }
};

export const restoreSession = (): AppThunk => async (dispatch) => {
  try {
    const uid = localStorage.getItem(AUTH_KEY);
    if (!uid) {
      // This means we have no cached user.
      console.debug('No cached uid found');
      dispatch(initialLoadCompleted());
      return;
    }

    // We found a cached uid, let's attempt to load the user.
    const profile = await getUser(uid);
    if (!profile) {
      // No active user matches the uid, so clear it.
      console.debug('Cached uid does not match a valid user');
      localStorage.removeItem(AUTH_KEY);
      dispatch(initialLoadCompleted());
      return;
    }

    console.info('User session restored', uid);
    dispatch(sessionRestored(profile));

    // Fetch the user's data.
    dispatch(initializeSession(uid));
  } catch (error) {
    console.error('Error attempting to restore session', error);
  }
};

/**
 * Performs initial data fetches and prepares the application state after a current user is loaded.
 *
 * @param userId The current user ID.
 */
export const initializeSession = (userId: string): AppThunk => async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchArticles(Inbox.triage)),
      dispatch(fetchArticles(Inbox.queue)),
      dispatch(fetchInboxCounts()),
      dispatch(fetchSubscriptions(userId)),
    ]);

    dispatch(articlesLoadCompleted());
    //console.log('');
  } catch (error) {
    console.error('Error initializing user session', error);
  }
};

/**
 * Thunk that signs out a user. We choose to simply ignore errors here and clear our state.
 */
export const performSignOut = (): AppThunk => async (dispatch) => {
  try {
    await signOut();
    console.info('Successful sign out from Supabase');
  } catch (error) {
    console.error('Error signing out Supabase user', error);
  } finally {
    // This needs to clear the auth cookie - it's okay if Supabase failed.
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem('supabase.auth.token');
    dispatch(signOutSuccess());
  }
};
