import React from 'react';
import { useSelector } from 'react-redux';

import { Article, Subscription } from '../../api/types';
import { useAppSelector } from '../../hooks/useState';
import { CardSize } from '../../state/newsletters';
import { AppState } from '../../store';
import WelcomeCard from '../inbox/WelcomeCard';
import SharedNavbar from '../navigation/SharedNavbar';
import Newsletter from '../subscription/Newsletter';
import SmallNewsletter from '../subscription/SmallNewsletter';

/**
 * Reading queue. Fetches articles from Redux.
 */

const Queue: React.FC = () => {
  const cardSize = useAppSelector((state) => state.newsletters.previewSize);

  const subscriptions = useSelector<AppState, Record<string, Subscription>>((state) => {
    const map: { [id: string]: Subscription } = {};
    Object.values(state.newsletters.subscriptions).forEach(({ subscription }) => {
      map[subscription.id] = subscription;
    });

    return map;
  });

  // Check number of subscription for onboarding view.
  const hasSubscriptions = useSelector<AppState, boolean>(
    (state) => Object.keys(state.newsletters.subscriptions).length > 0
  );

  const articles = useSelector<AppState, Article[]>((state) => {
    return state.newsletters.inboxes.queue.articleIds.map((articleId) => state.newsletters.articles[articleId]);
  });

  return (
    <>
      <SharedNavbar title="Reading Queue" />
      {hasSubscriptions ? (
        <>
          {articles.length > 0 ? (
            <div className="flex flex-col py-8 px-8">
              {cardSize === CardSize.large ? (
                <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
                  {articles.map((article, idx) => (
                    <Newsletter
                      key={article.id}
                      article={article}
                      subscription={subscriptions[article.subscription_id]}
                      fallbackImageIdx={idx}
                      fromQueue
                    />
                  ))}
                </div>
              ) : (
                <div className="border rounded-md">
                  {articles.map((article) => (
                    <SmallNewsletter
                      key={article.id}
                      article={article}
                      subscription={subscriptions[article.subscription_id]}
                      fromQueue
                    />
                  ))}
                </div>
              )}
            </div>
          ) : null}
        </>
      ) : (
        <div className="grid justify-items-center p-20">
          <WelcomeCard />
        </div>
      )}
    </>
  );
};

export default Queue;
