import { partition, sortBy, values } from 'ramda';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

import { Profile, SubscriptionState } from '../../api/types';
import logoIcon from '../../img/flowbox-logo.svg';
import downArrowIcon from '../../img/new/chevron-down-gray.svg';
import rightArrowIcon from '../../img/new/chevron-right-gray.svg';
import { AppState } from '../../store';
import { getSubscriptionEmail } from '../../util/email';
import withEmoji from '../withEmoji';
import styles from './Sidebar.module.css';

export const SIDEBAR_WIDTH = '240px';

const Container = styled.div`
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${SIDEBAR_WIDTH};
  display: flex;
  align-items: center;
  flex-direction: column;
  display: inline-flex;
  height: 100vh;
  z-index: 20;
  border-right: 1px solid #e5e7eb;
  overflow-y: scroll;
  overscroll-behavior: none;
`;

const LinkSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  margin-bottom: 4px;
`;

const PageLink = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 32px;

  &:hover {
    background-color: #ececf1;
    border-color: #ececf1;
  }

  &::first-of-kind {
    margin-top: 0;
  }
`;

const PageTitle = styled.div`
  display: flex;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  align-items: center;
  width: 200px;

  > span {
    margin-bottom: 5px !important;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  color: #b3b3b3;
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0 2px 20px;
  user-select: none;
  cursor: pointer;
  width: 100%;

  > img {
    padding-top: 3px;
  }

  > p {
    margin-left: 4px;
    padding-bottom: 3px;
  }
`;

const TitleText = styled.p`
  margin-left: 6px;
`;

const Badge = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  background-color: #f4b1af;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 20px;
  margin-left: auto;

  > p {
    padding: 5px 6px 6px 6px;
  }
`;

const SideBar: React.FC = () => {
  const { addToast } = useToasts();

  const [showStarred, setShowStarred] = useState(true);
  const [showSubscriptions, setShowSubscriptions] = useState(true);

  const user = useSelector<AppState, Profile | undefined>((state) => state.users.profile);

  const queueCount = useSelector<AppState, number>((state) => state.newsletters.inboxes.queue.count);
  const triageCount = useSelector<AppState, number>((state) => state.newsletters.inboxes.triage.count);

  const allSubscriptions = useSelector<AppState, SubscriptionState[]>((state) =>
    sortBy((s) => s.subscription.name || '', values(state.newsletters.subscriptions))
  );

  const [starred, subscriptions] = partition((s) => s.subscription.starred, allSubscriptions);

  const copyInbox = useCallback(() => {
    if (!user) {
      return;
    }

    const email = getSubscriptionEmail(user);
    navigator.clipboard.writeText(email);
    const options = {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 1500,
    };

    // @ts-expect-error The types for the addToast options payload are incorrect. See: https://github.com/jossmac/react-toast-notifications.
    addToast('Copied to clipboard!', options);
  }, [user, addToast]);

  return (
    <Container className="joyride-sidebar">
      {/* Top logo area */}
      <div
        className="fixed flex items-center justify-center h-16 border-b border-r"
        style={{ width: SIDEBAR_WIDTH, backgroundColor: '#f6f6f6' }}
      >
        <Link to="/">
          <img className="h-10" src={logoIcon} alt="flowbox" />
        </Link>
      </div>

      {/* Primary links */}
      <LinkSection style={{ marginTop: 80 }}>
        <NavLink to="/home" style={{ width: '100%' }} activeClassName={styles.active}>
          <PageLink>
            <PageTitle>{withEmoji(':inbox_tray: All Unread', TitleText)}</PageTitle>
            {triageCount > 0 ? (
              <Badge>
                <p>{triageCount}</p>
              </Badge>
            ) : null}
          </PageLink>
        </NavLink>
      </LinkSection>
      <LinkSection>
        <NavLink to="/read" style={{ width: '100%' }} activeClassName={styles.active}>
          <PageLink>
            <PageTitle>{withEmoji('📚 Reading Queue', TitleText)}</PageTitle>
            {queueCount > 0 ? (
              <Badge>
                <p>{queueCount}</p>
              </Badge>
            ) : null}
          </PageLink>
        </NavLink>
      </LinkSection>
      <LinkSection>
        <NavLink to="/archive" style={{ width: '100%' }} activeClassName={styles.active}>
          <PageLink>
            <PageTitle>{withEmoji(':package: Archive', TitleText)}</PageTitle>
          </PageLink>
        </NavLink>
      </LinkSection>
      <LinkSection>
        <NavLink to="/subscriptions" style={{ width: '100%' }} activeClassName={styles.active}>
          <PageLink>
            <PageTitle>{withEmoji(':rolled_up_newspaper: My Subscriptions', TitleText)}</PageTitle>
          </PageLink>
        </NavLink>
      </LinkSection>
      <LinkSection>
        <PageLink onClick={copyInbox} style={{ width: '100%', cursor: 'pointer' }}>
          <PageTitle>{withEmoji(`:paperclip: ${user ? user.mailbox_token : ''}`, TitleText)}</PageTitle>
        </PageLink>
      </LinkSection>

      {/* Starred subscriptions */}
      <HeaderTitle onClick={() => setShowStarred(!showStarred)}>
        <img className="w-4 pb-1.5" src={showStarred ? downArrowIcon : rightArrowIcon} alt="" />
        <p>Starred</p>
      </HeaderTitle>
      {showStarred
        ? starred.map(({ subscription, unread }) => (
            <LinkSection key={subscription.id}>
              <NavLink to={`/sub/${subscription.id}`} activeClassName={styles.active}>
                <PageLink>
                  <PageTitle>
                    <p className="ml-0.5 truncate mr-2">{subscription.name}</p>
                    {unread > 0 ? (
                      <Badge>
                        <p>{unread}</p>
                      </Badge>
                    ) : null}
                  </PageTitle>
                </PageLink>
              </NavLink>
            </LinkSection>
          ))
        : null}

      {/* Subscriptions */}
      <HeaderTitle onClick={() => setShowSubscriptions(!showSubscriptions)}>
        <img className="w-4 pb-1.5" src={showSubscriptions ? downArrowIcon : rightArrowIcon} alt="" />
        <p>All Subscriptions</p>
      </HeaderTitle>
      {showSubscriptions
        ? subscriptions.map(({ subscription, unread }) => (
            <LinkSection key={subscription.id}>
              <NavLink to={`/sub/${subscription.id}`} activeClassName={styles.active}>
                <PageLink>
                  <PageTitle>
                    <p className="ml-0.5 truncate mr-2">{subscription.name}</p>
                    {unread > 0 ? (
                      <Badge>
                        <p>{unread}</p>
                      </Badge>
                    ) : null}
                  </PageTitle>
                </PageLink>
              </NavLink>
            </LinkSection>
          ))
        : null}
    </Container>
  );
};

export default memo(SideBar);
