import { Profile } from '../api/types';

/**
 * Builds the email address that a user can use to subscribe to newsletters.
 * This handles deprecated addresses for users without usernames.
 */
export const getSubscriptionEmail = (user: Profile): string => {
  if (user.username) {
    return `${user.username}@in.flowbox.co`;
  }

  // Fallback for legacy users.
  return `${user.mailbox_token}@inbound.useflowbox.com`;
};
