/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Article, Subscription } from '../../api/types';
import useImages from '../../hooks/useImages';
import archiveIcon from '../../img/new/archive-white.svg';
import queueIcon from '../../img/new/bookmark-white.svg';
import removeIcon from '../../img/new/minus-circle-white.svg';
import unreadIcon from '../../img/new/unread-white.svg';
import { markArticleRead, markArticleUnread, markArticleUntriaged, triageArticle } from '../../state/newsletters';
import { images } from '../../util/images';

/**
 * New article preview card UI.
 */

interface NewsletterProps {
  className?: string;
  article: Article;
  subscription: Subscription;
  fromQueue?: boolean;
  fallbackImageIdx: number;
}

const TOAST_OPTIONS = {
  appearance: 'success',
  autoDismiss: true,
  autoDismissTimeout: 1000,
};

const Newsletter: React.FC<NewsletterProps> = ({ className, article, subscription, fromQueue, fallbackImageIdx }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const articleImages = useImages(article.content);
  const imageUrl = articleImages[0] || images[fallbackImageIdx];

  const triggerToast = useCallback(
    (message: string) => {
      // @ts-expect-error The types for the addToast options payload are incorrect. See: https://github.com/jossmac/react-toast-notifications.
      addToast(message, TOAST_OPTIONS);
    },
    [addToast]
  );

  const onArchive = useCallback(async () => {
    if (!article) {
      return;
    }
    //mark article as triaged and archived
    if (!article.triaged) {
      dispatch(triageArticle(article, false));
    }
    //mark article as archived/completed
    else {
      dispatch(markArticleRead(article));
    }
  }, [article, dispatch]);

  const onMarkUnread = useCallback(() => {
    if (!article) {
      return;
    }

    dispatch(markArticleUnread(article));
  }, [article, dispatch]);

  const onAddToQueue = useCallback(() => {
    if (!article) {
      return;
    }

    dispatch(triageArticle(article, true));
  }, [article, dispatch]);

  const onRemoveFromQueue = useCallback(() => {
    if (!article) {
      return;
    }

    dispatch(markArticleUntriaged(article));
  }, [article, dispatch]);

  const handleReadAction = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (article.archived) {
        onMarkUnread();
        triggerToast('Removed from archive!');
      } else {
        onArchive();
        triggerToast('Archived!');
      }
    },
    [article.archived, onArchive, onMarkUnread, triggerToast]
  );

  const handleQueueAction = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (article.triaged) {
        onRemoveFromQueue();
        triggerToast('Removed from queue!');
      } else {
        onAddToQueue();
        triggerToast('Added to queue!');
      }
    },
    [article.triaged, onAddToQueue, onRemoveFromQueue, triggerToast]
  );

  return (
    <div className={`group relative border bg-gray-50 border-red-50 hover:shadow-md rounded-md h-50 ${className}`}>
      <Link to={`/reader/${article.id}${fromQueue ? '?from=queue' : ''}`}>
        {/* Header */}
        <div className="flex items-center px-3 pt-1 pb-1.5">
          <p className="text-sm font-medium text-gray-500 truncate mr-2">{subscription.name}</p>

          <p className="ml-auto text-sm text-pink flex-shrink-0">{dayjs(article.created_at).format('MMM DD, YYYY')}</p>
        </div>

        {/* Main content */}
        <div className="flex flex-col">
          <div
            className={`w-full h-32 bg-cover bg-center bg-no-repeat bg-gray-50`}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
          <div className="px-3 pb-1.5 mt-1.5">
            <p className={`text-lg text-gray-900 line-clamp-1 ${article.archived ? '' : 'font-medium'}`}>
              {article.title}
            </p>
          </div>
        </div>

        <div className="w-full h-full fd-sh flex justify-end absolute right-0 top-0 opacity-0 rounded-md p-3 bg-black group-hover:opacity-60">
          <div className="flex items-baseline top-0 right-0 rounded-lg p-1 hover:opacity-100 font-bold text-lg">
            <img
              onClick={handleReadAction}
              className="w-5 transform transition hover:scale-125 mr-2"
              src={article.archived ? unreadIcon : archiveIcon}
              alt={article.archived ? 'mark unread' : 'archive'}
            />
            {!article.archived ? (
              <img
                onClick={handleQueueAction}
                className="w-5 transform transition hover:scale-125"
                src={article.triaged ? removeIcon : queueIcon}
                alt={article.triaged ? 'remove from queue' : 'add to queue'}
              />
            ) : null}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default memo(Newsletter);
