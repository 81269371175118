import React, { ReactNode } from 'react';
import { toArray } from 'react-emoji-render';
import { StyledComponent } from 'styled-components';

const withEmoji = (text: string, Wrapper: StyledComponent<any, any, any, any>): ReactNode => {
  const emojisArray = toArray(text);

  // toArray outputs React elements for emojis and strings for other.
  const components = emojisArray.map((parsed, idx) => {
    if (typeof parsed === 'string') {
      return <Wrapper key={idx}>{parsed}</Wrapper>;
    }
    return parsed;
  });

  return <>{[...components]}</>;
};

export default withEmoji;
