import React, { useCallback, useState } from 'react';

import { getUserByUsername } from '../../api/db';
import logoIcon from '../../img/flowbox-logo.svg';
import ErrorMessage from '../ui/ErrorMessage';

/**
 * Users must select a unique username which determines their mailbox token.
 */

interface UsernameProps {
  onCreateAccount: (username: string) => void;
  loading: boolean;
  error?: string;
}

const Username: React.FC<UsernameProps> = ({ onCreateAccount, loading, error }) => {
  const [username, setUsername] = useState('');
  const [validNames, setValidNames] = useState<string[]>([]);
  const [takenNames, setTakenNames] = useState<string[]>([]);

  const checkUsername = useCallback(async (check: string) => {
    const existing = await getUserByUsername(check);
    if (existing) {
      setTakenNames((existing) => [...existing, check]);
    } else {
      setValidNames((existing) => [...existing, check]);
    }
  }, []);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setUsername(value);
      checkUsername(value);
    },
    [checkUsername]
  );

  const onSubmit = useCallback(() => {
    if (!username) {
      return;
    }

    onCreateAccount(username);
  }, [username, onCreateAccount]);

  const isValidUsername = validNames.includes(username);
  const isTakenUsername = takenNames.includes(username);

  let errorMessage = error;
  if (isTakenUsername) {
    errorMessage = `This one's taken :(`;
  }

  return (
    <div className="flex items-center justify-center h-full mx-auto max-w-5xl">
      <div className="absolute top-3 left-5">
        <img className="h-12" src={logoIcon} alt="logo" />
      </div>

      <div className="mx-8 flex flex-col items-center border bg-gray-50 px-12 pt-10 pb-8 rounded-lg">
        <h1 className="text-3xl font-medium">Choose a username</h1>
        <h3 className="text-lg mt-1">Your username will determine the address you use to subscribe to newsletters</h3>

        <div className="flex items-center mt-8">
          <input
            className="focus:border-light-blue-500 bg-gray-50 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full max-w-xs text-base text-black placeholder-gray-400 border border-gray-200 rounded-md py-2 px-3"
            type="text"
            placeholder="Username"
            onChange={onChange}
            value={username}
          />
          <p className="ml-1 font-medium text-lg">@in.flowbox.co</p>
        </div>

        {errorMessage && <ErrorMessage className="mt-5" error={errorMessage} />}

        <button
          className={`bg-pink rounded-md text-white px-8 py-2 font-semibold disabled:opacity-70 focus:outline-none ${
            errorMessage ? 'mt-5' : 'mt-20'
          } `}
          disabled={loading || !isValidUsername}
          onClick={onSubmit}
        >
          Sign up
        </button>
      </div>
    </div>
  );
};

export default Username;
