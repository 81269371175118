import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import { restoreSession } from './state/users';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize the session at launch.
    dispatch(restoreSession());
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <PrivateRoute path="/" component={Home} />
      </Switch>
    </>
  );
};

export default App;
