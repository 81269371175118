import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getArticles } from '../../api/db';
import { Article, Inbox, Profile, Subscription } from '../../api/types';
import { useAppSelector } from '../../hooks/useState';
import { CardSize } from '../../state/newsletters';
import { AppState } from '../../store';
import SharedNavbar from '../navigation/SharedNavbar';
import Newsletter from '../subscription/Newsletter';
import SmallNewsletter from '../subscription/SmallNewsletter';

const Archive: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);

  const cardSize = useAppSelector((state) => state.newsletters.previewSize);

  const subscriptions = useSelector<AppState, Record<string, Subscription>>((state) => {
    const map: { [id: string]: Subscription } = {};
    Object.values(state.newsletters.subscriptions).forEach(({ subscription }) => {
      map[subscription.id] = subscription;
    });

    return map;
  });

  const user = useSelector<AppState, Profile | undefined>((state) => {
    return state.users.profile;
  });

  const fetchArticles = useCallback(async (userId: string) => {
    const res = await getArticles(userId, Inbox.archive);

    setArticles(res);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      fetchArticles(user.uid);
    }
  }, [user, fetchArticles]);

  const renderArticles = useCallback(() => {
    if (loading || articles.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col py-8 px-8">
        {cardSize === CardSize.large ? (
          <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
            {articles.map((article, idx) => (
              <Newsletter
                key={article.id}
                article={article}
                subscription={subscriptions[article.subscription_id]}
                fallbackImageIdx={idx}
              />
            ))}
          </div>
        ) : (
          <div className="border rounded-md">
            {articles.map((article) => (
              <SmallNewsletter
                key={article.id}
                article={article}
                subscription={subscriptions[article.subscription_id]}
              />
            ))}
          </div>
        )}
      </div>
    );
  }, [loading, cardSize, articles, subscriptions]);

  return (
    <>
      <SharedNavbar title="Archive" />

      {renderArticles()}
    </>
  );
};

export default Archive;
