/**
 * Redux store slice related to user categories and article tags.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { indexBy } from 'ramda';

import { createCategory, getCategories } from '../api/db';
import { Category } from '../api/types';
import { AppThunk, ThunkResult } from '../store';

interface CategoryState {
  // All user categories indexed by ID.
  categories: { [key: string]: Category };
  creatingCategory: boolean;
  fetchingCategories: boolean;
}

const initialState: CategoryState = {
  categories: {},
  creatingCategory: false,
  fetchingCategories: true,
};

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    getCategoriesStarted(state) {
      state.fetchingCategories = true;
    },
    getCategoriesSuccess(state, action: PayloadAction<Category[]>) {
      state.fetchingCategories = false;
      state.categories = indexBy((category) => category.id, action.payload);
    },
    createCategoryStarted(state) {
      state.creatingCategory = true;
    },
    createCategorySuccess(state, action: PayloadAction<Category | undefined>) {
      state.creatingCategory = false;
      const category = action.payload;
      if (category) {
        state.categories[category.id] = category;
      }
    },
    updateCategorySuccess(state, action: PayloadAction<Category | undefined>) {
      const category = action.payload;
      console.log('updated cat reducer');
      if (category) {
        state.categories[category.id] = category;
      }
    },
  },
});

export const {
  getCategoriesStarted,
  getCategoriesSuccess,
  createCategoryStarted,
  createCategorySuccess,
  updateCategorySuccess,
} = categorySlice.actions;

export default categorySlice.reducer;

/**
 * Fetch the user's categories from the DB.
 *
 * @param userId The user ID.
 */
export const fetchCategories = (userId: string): AppThunk => async (dispatch) => {
  try {
    const categories = await getCategories(userId);
    dispatch(getCategoriesSuccess(categories));
  } catch (error) {
    console.error(`Error fetching user categories`, error);
    dispatch(getCategoriesSuccess([]));
  }
};

/**
 * Create a new category.
 *
 * @param name The category name.
 */
export const createNewCategory = (name: string): ThunkResult<Promise<Category | undefined>> => async (
  dispatch,
  getState
) => {
  const user = getState().users.profile;
  if (!user) {
    console.error(`Unable to create new category ${name} because no current user was found`);
    return Promise.resolve(undefined);
  }

  try {
    const category = await createCategory(user.uid, name);
    console.info(`Successfully created new category ${name}`);
    dispatch(createCategorySuccess(category));
    return category;
  } catch (error) {
    console.error(`Failed to create new category ${name}`, error);
    dispatch(createCategorySuccess());
  }
};
