import dayjs from 'dayjs';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { Article, Subscription } from '../../api/types';

/**
 * Compact email-style article previews.
 */

interface SmallNewsletterProps {
  className?: string;
  article: Article;
  subscription: Subscription;
  fromQueue?: boolean;
}

const SmallNewsletter: React.FC<SmallNewsletterProps> = ({ className, article, subscription, fromQueue }) => {
  return (
    <div className={`hover:bg-gray-50 h-10 ${className}`}>
      <Link to={`/reader/${article.id}${fromQueue ? '?from=queue' : ''}`}>
        <div className="flex items-center px-3 py-2">
          <p className={`text-gray-900 truncate ${article.archived ? '' : 'font-medium'}`}>{article.title}</p>
          <p className={`text-sm text-gray-500 truncate mx-2 ${article.archived ? '' : 'font-medium'}`}>
            {subscription.name}
          </p>

          <p
            className={`ml-auto text-sm text-pink border-transparent flex-shrink-0 ${
              article.archived ? '' : 'font-medium'
            }`}
          >
            {dayjs(article.created_at).format('MMM DD, YYYY')}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default memo(SmallNewsletter);
