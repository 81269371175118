import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Archive from '../components/archive/Archive';
import Article from '../components/article/Article';
import Inbox from '../components/inbox/Inbox';
import EmailForwardingModal from '../components/navigation/EmailForwardingModal';
import Queue from '../components/queue/Queue';
import Sidebar, { SIDEBAR_WIDTH } from '../components/sidebar/Sidebar';
import Subscription from '../components/subscription/Subscription';
import Subscriptions from '../components/subscriptions/Subscriptions';
import { useAppSelector } from '../hooks/useState';
import { toggleForwardingModal } from '../state/users';

const Page = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
  height: 100%;
`;

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const emailModal = useAppSelector((state) => state.users.showingForwardingModal);

  const dismissEmailModal = useCallback(() => {
    dispatch(toggleForwardingModal());
  }, [dispatch]);

  return (
    <Page>
      <Sidebar />
      <EmailForwardingModal showing={emailModal} onDismiss={dismissEmailModal} />
      <Content>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route exact path="/home" component={Inbox} />
          <Route exact path="/read" component={Queue} />
          <Route exact path="/archive" component={Archive} />
          <Route exact path="/subscriptions" component={Subscriptions} />
          <Route path="/sub/:id" children={<Subscription />} />
          <Route path="/reader/:id" children={<Article />} />
        </Switch>
      </Content>
    </Page>
  );
};

export default Home;
