/**
 * The application's Redux Store.
 *
 * We use Redux Toolkit to provide saner APIs on top of Redux.
 * See https://redux-toolkit.js.org/.
 */

import { Action, configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';
import { ThunkAction } from 'redux-thunk';

import createRootReducer from './rootReducer';

export const history = createBrowserHistory();

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      return getDefaultMiddleware().concat([logger, routerMiddleware(history)]);
    }
    return getDefaultMiddleware().concat([routerMiddleware(history)]);
  },
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    store.replaceReducer(createRootReducer(history));
  });
}

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, AppState, unknown, Action<string>>;
export type ThunkResult<R> = ThunkAction<R, AppState, null, Action<string>>;

export default store;
