import React, { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../hooks/useState';
import astronaut from '../../img/Astronaut.png';
import { toggleForwardingModal } from '../../state/users';
import { getSubscriptionEmail } from '../../util/email';

const Title = styled.p`
  margin-bottom: 30px;
  text-align: center;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
`;

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 13px 5px rgba(176, 176, 176, 0.25);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 60px;
  width: 600px;
  font-size: 18px;
  line-height: 22px;
  &:hover {
    box-shadow: 0px 0px 13px 5px rgba(176, 176, 176, 0.4);
  }
`;

const WelcomeCard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const user = useAppSelector((state) => state.users.profile);

  const openNotification = () => {
    let email = '';
    if (user) {
      email = getSubscriptionEmail(user);
    }
    navigator.clipboard.writeText(email);

    const options = {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 1500,
    };

    // @ts-expect-error The types for the addToast options payload are incorrect. See: https://github.com/jossmac/react-toast-notifications.
    addToast('Copied to clipboard!', options);
  };

  const forwardingModal = useCallback(() => {
    dispatch(toggleForwardingModal());
  }, [dispatch]);

  return (
    <Container>
      <img className="w-40 m-auto fill-current-gray-500" src={astronaut} alt="reading"></img>
      <Title>Your newsletter summaries will appear here, {user ? user.name : ''}</Title>
      <p className="pb-2">Subscribe to your favorite newsletters by using your custom email:</p>
      <div className="bg-gray-100 rounded-lg hover:shadow-md">
        <div className="p-2">
          <div
            onClick={openNotification}
            onKeyPress={openNotification}
            role="button"
            tabIndex={0}
            className="flex flex-col"
          >
            <h4 className="text-xl font-semibold text-center">{user ? getSubscriptionEmail(user) : ''}</h4>
          </div>
        </div>
      </div>
      <p className="mt-8">
        Or you can forward from your email.{' '}
        <span className="text-red-300" onKeyDown={forwardingModal} onClick={forwardingModal} tabIndex={0} role="button">
          Click here{' '}
        </span>
        for instructions!
      </p>
    </Container>
  );
};

export default WelcomeCard;
