/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/useState';
import { CardSize, setPreviewSize } from '../../state/newsletters';
import { performSignOut, toggleForwardingModal } from '../../state/users';

/**
 * Dropdown menu with user and app settings.
 */

const NavMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const cardSize = useAppSelector((state) => state.newsletters.previewSize);

  const onSignOut = useCallback(() => {
    dispatch(performSignOut());
  }, [dispatch]);

  const onToggleCardSize = useCallback(() => {
    dispatch(setPreviewSize(cardSize === CardSize.large ? CardSize.small : CardSize.large));
  }, [dispatch, cardSize]);

  const onEmailForwarding = useCallback(() => {
    dispatch(toggleForwardingModal());
  }, [dispatch]);

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <div className="flex flex-col bg-gray-50 w-40 py-1 rounded-md">
      {/* About */}
      <div
        className="cursor-pointer hover:bg-gray-100 py-1 focus:outline-none"
        onClick={() => openInNewTab('https://www.notion.so/Welcome-to-Flowbox-524589f835b04d36afed9a575bdc03d8')}
        onKeyDown={() => openInNewTab('https://www.notion.so/Welcome-to-Flowbox-524589f835b04d36afed9a575bdc03d8')}
        role="button"
        tabIndex={0}
      >
        <p className="cursor-pointer text-sm px-4 font-medium">About</p>
      </div>

      {/* Feedback */}
      <div
        className="cursor-pointer hover:bg-gray-100 py-1 focus:outline-none"
        onClick={() => openInNewTab('https://t.me/joinchat/SMGhuBz10BeP5g0JBcvXIg')}
        onKeyDown={() => openInNewTab('https://t.me/joinchat/SMGhuBz10BeP5g0JBcvXIg')}
        role="button"
        tabIndex={0}
      >
        <p className="cursor-pointer text-sm px-4 font-medium">Give feedback</p>
      </div>

      {/* Toggle card size */}
      <div
        className="cursor-pointer hover:bg-gray-100 py-1 focus:outline-none "
        onClick={onToggleCardSize}
        onKeyDown={onToggleCardSize}
        role="button"
        tabIndex={0}
      >
        <p className="cursor-pointer text-sm px-4 font-medium">
          {cardSize === CardSize.large ? 'View small previews' : 'View full previews'}
        </p>
      </div>

      {/* Gmail forwarding instructions */}
      <div
        className="cursor-pointer hover:bg-gray-100 py-1 focus:outline-none"
        onClick={onEmailForwarding}
        onKeyDown={onEmailForwarding}
        role="button"
        tabIndex={0}
      >
        <p className="cursor-pointer text-sm px-4 font-medium">Gmail forwarding</p>
      </div>

      {/* Sign out */}
      <div
        className="cursor-pointer hover:bg-gray-100 py-1 focus:outline-none"
        onClick={onSignOut}
        onKeyDown={onSignOut}
        role="button"
        tabIndex={0}
      >
        <p className="cursor-pointer text-sm px-4 font-medium">Sign out</p>
      </div>
    </div>
  );
};

export default memo(NavMenu);
