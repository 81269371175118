import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { Subscription } from '../../api/types';
import unsubscribe from '../../img/new/unsubscribe.png';
import substackIcon from '../../img/substack.png';

interface SubscriptionListItemProps {
  subscription: Subscription;
}

const Item = styled.div`
  display: flex;
  margin: 10px 0;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
`;

const NewslettterImage = styled.img`
  height: 24px;
  width: 24px;
`;

const NameLabel = styled.p`
  display: inline-block;
  color: #101010;
  font-size: 17px;
  font-weight: 500;
  margin: 0 8px 0 12px;
`;

const SiteLabel = styled.p`
  color: #4b4b4b;
  display: inline-block;
  font-size: 15px;
  margin: 0;

  > a {
    color: #4b4b4b;
  }

  > a:hover {
    text-decoration: underline;
  }
`;

const Timestamp = styled.div`
  margin-left: auto;
  margin-right: 24px;
  width: 200px;
`;

const SubscriptionListItem: React.FC<SubscriptionListItemProps> = ({ subscription }) => {
  return (
    <Item>
      <Meta>
        <NewslettterImage src={substackIcon}></NewslettterImage>
        <NameLabel>{subscription.name || 'Subscription'}</NameLabel>
        {subscription.url ? (
          <SiteLabel>
            <a href={`https://${subscription.url}`} target="_blank" rel="noreferrer">
              {subscription.url}
            </a>
          </SiteLabel>
        ) : null}
      </Meta>
      <Timestamp>
        <div className="flex justify-between float-right">
          {subscription.unsubscribe_url ? (
            <a href={subscription.unsubscribe_url} target="_blank" rel="noreferrer">
              <img src={unsubscribe} className="w-4 mt-1" alt="unsubscribe" />
            </a>
          ) : null}
          <p className="pl-2 pr-5 text-base text-gray-600">{dayjs(subscription.updated_at).format('MMM DD, YYYY')}</p>
        </div>
      </Timestamp>
    </Item>
  );
};

export default SubscriptionListItem;
