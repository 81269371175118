/**
 * Redux store slice related to newsletter subscriptions and articles.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { append, indexBy, prepend, without } from 'ramda';
import { flatten } from 'ramda';

import {
  getArticleCount,
  getArticles,
  getSubscriptions,
  getSubscriptionUnreadCount,
  updateArticle,
  updateSubscription,
} from '../api/db';
import { Article, Inbox, InboxState, Subscription, SubscriptionState } from '../api/types';
import { AppState, AppThunk } from '../store';
import { getNextItem } from '../util/general';

export enum CardSize {
  small = 'small',
  large = 'large',
}

interface NewsletterState {
  // All articles indexed by ID.
  articles: { [id: string]: Article };
  fetchingArticles: boolean;

  // All subscriptions indexed by ID along with metadata.
  subscriptions: { [id: string]: SubscriptionState };
  fetchingSubscriptions: boolean;

  // A list of the IDs of articles keyed by inbox type.
  inboxes: {
    [key in Inbox]: InboxState;
  };

  flowMode: boolean;
  previewSize: CardSize;

  // TODO: No longer used.
  currentArticles: { [inbox: string]: string | undefined };
}

interface ArticlesPayload {
  inbox: Inbox;
  articles: Article[];
}

interface InboxCountPayload {
  inbox: Inbox;
  count: number;
}

interface SubscriptionUnreadCountPayload {
  subscriptionId: string;
  unread: number;
}

const initialState: NewsletterState = {
  articles: {},
  fetchingArticles: false,

  subscriptions: {},
  fetchingSubscriptions: true,

  currentArticles: {},

  inboxes: {
    [Inbox.archive]: { articleIds: [], count: 0 },
    [Inbox.queue]: { articleIds: [], count: 0 },
    [Inbox.triage]: { articleIds: [], count: 0 },
  },

  flowMode: true,
  previewSize: CardSize.large,
};

const newsletterSlice = createSlice({
  name: 'newsletters',
  initialState,
  reducers: {
    getSubscriptionsStarted(state) {
      state.fetchingSubscriptions = true;
    },
    getSubscriptionsSuccess(state, action: PayloadAction<Subscription[]>) {
      state.fetchingSubscriptions = false;
      action.payload.forEach((subscription) => {
        const existing = state.subscriptions[subscription.id];
        if (existing) {
          existing.subscription = subscription;
        } else {
          state.subscriptions[subscription.id] = { subscription, unread: 0 };
        }
      });
    },
    getSubscriptionUnreadCounts(state, action: PayloadAction<SubscriptionUnreadCountPayload[]>) {
      action.payload.forEach(({ subscriptionId, unread }) => {
        const existing = state.subscriptions[subscriptionId];
        if (existing) {
          existing.unread = unread;
        } else {
          console.warn('Fetched unread count for subscription which is not in state', subscriptionId);
        }
      });
    },
    getArticlesStarted(state) {
      state.fetchingArticles = true;
    },
    getArticlesSuccess(state, action: PayloadAction<ArticlesPayload>) {
      state.fetchingArticles = false;
      const articles = indexBy((article) => article.id, action.payload.articles);
      const articleIds = action.payload.articles.map((article) => article.id);
      state.articles = { ...state.articles, ...articles };
      state.inboxes[action.payload.inbox].articleIds = articleIds;

      // If we fetched the reading queue and don't have a current article, set it.
      if (action.payload.inbox === Inbox.queue && !state.currentArticles.queue) {
        state.currentArticles.queue = articleIds[0];
      }
    },
    getInboxCounts(state, action: PayloadAction<InboxCountPayload[]>) {
      action.payload.forEach(({ inbox, count }) => {
        state.inboxes[inbox].count = count;
      });
    },
    readArticle(state, action: PayloadAction<Article>) {
      // Update article.
      const article = action.payload;
      state.articles[article.id] = article;

      // Remove from reading queue if present.
      if (state.inboxes.queue.articleIds.includes(article.id)) {
        const nextArticleId = getNextItem(article.id, state.inboxes.queue.articleIds);
        const queue = without([article.id], state.inboxes.queue.articleIds);

        state.inboxes.queue.articleIds = queue;
        state.inboxes.queue.count -= 1;

        state.currentArticles[Inbox.queue] = nextArticleId;
      }

      // Decrement subscription unreads.
      const subscription = state.subscriptions[article.subscription_id];
      if (subscription) {
        subscription.unread -= 1;
      }

      // Add to archive.
      state.inboxes.archive.articleIds = prepend(article.id, state.inboxes.archive.articleIds);
      state.inboxes.archive.count += 1;
    },
    setArticleUnread(state, action: PayloadAction<Article>) {
      // Update article.
      const article = action.payload;
      state.articles[article.id] = article;
      // Remove from archive.
      state.inboxes.archive.articleIds = without([article.id], state.inboxes.archive.articleIds);
      state.inboxes.archive.count -= 1;
      // Increment subscription unreads.
      const subscription = state.subscriptions[article.subscription_id];
      if (subscription) {
        subscription.unread += 1;
      }
    },
    untriageArticleFromQueue(state, action: PayloadAction<Article>) {
      // Update article.
      const article = action.payload;
      state.articles[article.id] = article;

      // Remove from queue if present.
      if (state.inboxes.queue.articleIds.includes(article.id)) {
        state.inboxes.queue.articleIds = without([article.id], state.inboxes.queue.articleIds);
        state.inboxes.queue.count -= 1;
      }

      // Add to triage.
      state.inboxes.triage.articleIds = append(article.id, state.inboxes.triage.articleIds);
      state.inboxes.triage.count += 1;
    },
    triageArticleIntoQueue(state, action: PayloadAction<Article>) {
      // Update article.
      const article = action.payload;
      state.articles[article.id] = article;

      // Remove from triage if present.
      if (state.inboxes.triage.articleIds.includes(article.id)) {
        state.inboxes.triage.articleIds = without([article.id], state.inboxes.triage.articleIds);
        state.inboxes.triage.count -= 1;
      }

      // Add to queue.
      state.inboxes.queue.articleIds = append(article.id, state.inboxes.queue.articleIds);
      state.inboxes.queue.count += 1;
    },
    triageArticleIntoArchive(state, action: PayloadAction<Article>) {
      // Update article.
      const article = action.payload;
      state.articles[article.id] = article;

      // Remove from triage and queue.
      if (state.inboxes.triage.articleIds.includes(article.id)) {
        state.inboxes.triage.articleIds = without([article.id], state.inboxes.triage.articleIds);
        state.inboxes.triage.count -= 1;
      }

      if (state.inboxes.queue.articleIds.includes(article.id)) {
        state.inboxes.queue.articleIds = without([article.id], state.inboxes.queue.articleIds);
        state.inboxes.queue.count -= 1;
      }

      // Decrement subscription unreads.
      const subscription = state.subscriptions[article.subscription_id];
      if (subscription) {
        subscription.unread -= 1;
      }

      // Add to archive.
      state.inboxes.archive.articleIds = prepend(article.id, state.inboxes.archive.articleIds);
      state.inboxes.archive.count += 1;
    },
    skipQueuedArticle(state, action: PayloadAction<Article>) {
      // Move to the next article.
      const articleId = action.payload.id;
      const nextArticleId = getNextItem(articleId, state.inboxes.queue.articleIds);

      state.currentArticles[Inbox.queue] = nextArticleId;
    },
    setCurrentArticleId(state, action: PayloadAction<string>) {
      state.currentArticles[Inbox.queue] = action.payload;
    },
    setSubscription(state, action: PayloadAction<Subscription>) {
      const subscription = action.payload;
      const existing = state.subscriptions[subscription.id];
      if (existing) {
        existing.subscription = subscription;
      } else {
        state.subscriptions[subscription.id].subscription = subscription;
      }
    },
    setPreviewSize(state, action: PayloadAction<CardSize>) {
      state.previewSize = action.payload;
    },
    setFlowMode(state, action: PayloadAction<boolean>) {
      state.flowMode = action.payload;
    },
  },
});

export const {
  getSubscriptionsStarted,
  getSubscriptionsSuccess,
  getSubscriptionUnreadCounts,
  getArticlesStarted,
  getArticlesSuccess,
  getInboxCounts,
  readArticle,
  setArticleUnread,
  skipQueuedArticle,
  untriageArticleFromQueue,
  triageArticleIntoQueue,
  triageArticleIntoArchive,
  setCurrentArticleId,
  setSubscription,
  setPreviewSize,
  setFlowMode,
} = newsletterSlice.actions;

export default newsletterSlice.reducer;

/**
 * THUNKS
 */

/**
 * Loads all of a user's active subscriptions.
 *
 * @param userId The user ID.
 */
export const fetchSubscriptions = (userId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getSubscriptionsStarted());
    const subscriptions = await getSubscriptions(userId);
    dispatch(getSubscriptionsSuccess(subscriptions));
    dispatch(fetchSubscriptionCounts(userId));
  } catch (error) {
    console.error('Error fetching user subscriptions', error);
    dispatch(getSubscriptionsSuccess([]));
  }
};

/**
 * Loads the unread counts for all of a user's subscriptions. Requires subscriptions to be present.
 */
export const fetchSubscriptionCounts = (userId: string): AppThunk => async (dispatch, getState) => {
  try {
    const subscriptionIds = Object.keys(getState().newsletters.subscriptions);
    const counts = await Promise.all(
      subscriptionIds.map((subscriptionId) => getSubscriptionUnreadCount(userId, subscriptionId))
    );

    const payloads = subscriptionIds.map((subscriptionId, idx) => ({ subscriptionId, unread: counts[idx] }));
    dispatch(getSubscriptionUnreadCounts(payloads));
  } catch (error) {
    console.error('Error fetching user subscription counts', error);
  }
};

/**
 * Sets a subscription as starred or removes the star.
 */
export const setSubscriptionStarred = (subscription: Subscription, starred: boolean): AppThunk => async (dispatch) => {
  try {
    // Optimistic update.
    const optimistic = { ...subscription, starred };
    dispatch(setSubscription(optimistic));

    // Update via db.
    const updated = await updateSubscription(subscription.id, { starred });
    dispatch(setSubscription(updated));
  } catch (error) {
    console.error('Error updating starred status for subscription');
  }
};

/**
 * Loads all articles for a given inbox.
 * This requires the current user's profile to be set in the app state.
 *
 * @param inbox The inbox for which to fetch articles.
 */
export const fetchArticles = (inbox: Inbox): AppThunk => async (dispatch, getState) => {
  try {
    const user = getState().users.profile;
    if (!user) {
      console.warn(`Can't fetch articles for inbox ${inbox} because no current user was found`);
      return;
    }
    const articles = await getArticles(user.uid, inbox);
    dispatch(getArticlesSuccess({ inbox, articles }));
  } catch (error) {
    console.error(`Error fetching articles for inbox ${inbox}`, error);
    dispatch(getArticlesSuccess({ inbox, articles: [] }));
  }
};

/**
 * Fetches total counts for articles in the triage and reading queue.
 */
export const fetchInboxCounts = (): AppThunk => async (dispatch, getState) => {
  try {
    const user = getState().users.profile;
    if (!user) {
      console.warn(`Can't fetch inbox article counts because no current user was found`);
      return;
    }

    const triageCount = await getArticleCount(user.uid, { triaged: false });
    const triagePayload: InboxCountPayload = { inbox: Inbox.triage, count: triageCount };

    const queueCount = await getArticleCount(user.uid, { triaged: true, archived: false });
    const queuePayload: InboxCountPayload = { inbox: Inbox.queue, count: queueCount };

    dispatch(getInboxCounts([triagePayload, queuePayload]));
  } catch (error) {
    console.error('Error fetching count of articles in inbox', error);
  }
};

/**
 * Function to process an article from the triage list.
 * This will either archive the article or move it to the reading queue.
 *
 * @param article The article.
 * @param toRead Whether to place the article in the reading queue.
 * @param categoryId The optional category to tag this article with.
 */
export const triageArticle = (article: Article, toRead: boolean, categoryId?: string): AppThunk => async (dispatch) => {
  try {
    const updated = { ...article, updated_at: new Date() };
    updated.triaged = true;
    updated.archived_at = new Date();
    if (toRead) {
      updated.archived = false;
      updated.completed = false;
    } else {
      updated.archived = true;
    }
    if (categoryId) {
      updated.tags = [categoryId];
    }

    // Optimistically update the store.
    if (toRead) {
      dispatch(triageArticleIntoQueue(updated));
    } else {
      dispatch(triageArticleIntoArchive(updated));
    }

    // Update via DB.
    updateArticle(article.id, updated);
  } catch (error) {
    console.error(`Error triaging article ${article.id}`, error);
  }
};

/**
 * Function to mark an article as read (archive it).
 *
 * @param article The article.
 */
export const markArticleRead = (article: Article): AppThunk => async (dispatch) => {
  try {
    const updated = { ...article };
    updated.archived = true;
    updated.archived_at = new Date();
    updated.completed = true;
    updated.updated_at = new Date();

    // Optimistically update the store.
    dispatch(readArticle(updated));
    // Update the DB.
    updateArticle(article.id, updated);
  } catch (error) {
    console.error(`Error completing article ${article.id}`, error);
  }
};

/**
 * Function to mark an article untriaged from queue and send it back to triage queu
 *
 * @param article The article.
 */
export const markArticleUntriaged = (article: Article): AppThunk => async (dispatch) => {
  try {
    const updated = { ...article };
    updated.archived = false;
    updated.triaged = false;
    updated.archived_at = new Date();
    updated.completed = false;
    updated.updated_at = new Date();

    // Optimistically update the store.
    dispatch(untriageArticleFromQueue(updated));
    // Update the DB.
    updateArticle(article.id, updated);
  } catch (error) {
    console.error(`Error marking article untriaged ${article.id}`, error);
  }
};

/**
 * Function to mark an article as unread.
 *
 * @param article The article.
 */
export const markArticleUnread = (article: Article): AppThunk => async (dispatch, getState) => {
  try {
    const updated = { ...article };
    updated.archived = false;
    updated.archived_at = new Date();
    updated.completed = false;
    updated.updated_at = new Date();

    // Optimistically update the store.
    dispatch(setArticleUnread(updated));
    // Update ethe DB.
    updateArticle(article.id, updated);
  } catch (error) {
    console.error(`Error marking article unread ${article.id}`, error);
  }
};

/**
 * SELECTORS
 */

/**
 * Returns a list of articles tagged with the given category.
 *
 * @param state The application Redux state.
 * @param categoryId The tagged category.
 */
export const getArticlesInCategory = (state: AppState, categoryId: string): Article[] => {
  if (!categoryId) {
    return [];
  }
  return flatten(
    state.newsletters.inboxes.queue.articleIds.map((articleId) => {
      const article = state.newsletters.articles[articleId];
      if (!article) {
        return undefined;
      }
      if (article.tags?.includes(String(categoryId))) {
        return article;
      }
      return undefined;
    })
  ).filter((article) => !!article) as Article[];
};

/**
 * Returns the IDs of the queued articles in an inbox or category tag.
 *
 * @param inbox The inbox or category tag.
 */
export const getQueuedArticleIds = (state: AppState, inbox: string): string[] => {
  // Check for the special inboxes.
  if (inbox === Inbox.queue) {
    return state.newsletters.inboxes.queue.articleIds;
  }
  if (inbox === Inbox.archive) {
    return state.newsletters.inboxes.archive.articleIds;
  }
  // Treat as category.
  return getArticlesInCategory(state, inbox).map((article) => article.id);
};
