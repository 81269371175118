/**
 * Module that uses the supabase client to interact with the remote data store.
 *
 * See https://supabase.io/docs/library/getting-started.
 */

import { createClient, User } from '@supabase/supabase-js';
import bcrypt from 'bcryptjs';

import { getRandomEmoji } from '../util/emoji';
import { toSlug } from '../util/ids';
import { Category, Inbox } from './types';
import { Article, Profile, Subscription } from './types';

// Salt rounds to use when hashing passwords.
const BCRYPT_SALT_ROUNDS = 10;

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseApiKey = process.env.REACT_APP_SUPABASE_KEY || '';
if (!supabaseUrl || !supabaseApiKey) {
  throw new Error('Supabase configuration not found');
}

const supabase = createClient(supabaseUrl, supabaseApiKey);

/**
 * AUTH
 */

/**
 * Create a new user.
 */
export const signUp = async (email: string, password: string): Promise<User> => {
  const { user, session, error } = await supabase.auth.signUp({
    email,
    password,
  });

  if (error || !user) {
    console.error('Error signing up', error);
    throw error;
  }

  console.info('Successfully signed up', user, session);
  return user;
};

/**
 * Authenticate a user.
 */
export const signIn = async (email: string, password: string): Promise<User> => {
  const { user, session, error } = await supabase.auth.signIn({
    email,
    password,
  });

  if (error || !user) {
    console.error('Error signing in', error);
    throw error;
  }

  console.info('Successfully signed in', user, session);
  return user;
};

/**
 * Sign out. Currently ignores errors.
 */
export const signOut = async (): Promise<void> => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.log('Error siging out', error);
  }
};

/**
 * PROFILES
 */

export const getUser = async (userId: string): Promise<Profile | undefined> => {
  const { data, error } = await supabase.from<Profile>('profiles').select().eq('uid', userId);

  if (data && data.length > 0) {
    return data[0];
  }
  if (error) {
    console.error('Error fetching user profile', error);
  }

  return undefined;
};

export const getUserByEmail = async (email: string): Promise<Profile | undefined> => {
  const { data, error } = await supabase.from<Profile>('profiles').select().match({ email });

  if (data && data.length > 0) {
    return data[0];
  }
  if (error) {
    console.error('Error fetching user profile by email', error);
  }

  return undefined;
};

export const getUserByUsername = async (username: string): Promise<Profile | undefined> => {
  const { data, error } = await supabase.from<Profile>('profiles').select().match({ username });

  if (data && data.length > 0) {
    return data[0];
  }
  if (error) {
    console.error('Error fetching user profile by username', error);
  }

  return undefined;
};

export const getUserByToken = async (token: string): Promise<Profile | undefined> => {
  const { data, error } = await supabase.from<Profile>('profiles').select().match({ mailbox_token: token });

  if (data && data.length > 0) {
    return data[0];
  }
  if (error) {
    console.error('Error fetching user profile by token', error);
  }

  return undefined;
};

/**
 * Create a new profile for a user. This uses the same email and password for future
 * flexibility in case we move off of Supabase, and adds additional metadata that's
 * needed for the app.
 */
export const createProfileForUser = async (
  uid: string,
  name: string,
  email: string,
  username: string,
  password: string
): Promise<Profile> => {
  // Mailbox tokens are the initial username.

  const profile: Partial<Profile> = {
    uid,
    name,
    email,
    username,
    password: await hashPassword(password),
    mailbox_token: username,
    active: true,
  };

  const { data, error } = await supabase.from<Profile>('profiles').insert([profile]);

  if (error) {
    console.error('Error creating user profile', error);
    throw error;
  }
  if (!data || !data[0]) {
    console.error('Nothing returned from create profile call');
    throw new Error('Empty create profile response');
  }

  return data[0];
};

/**
 * SUBSCRIPTIONS
 */

/**
 * Retrieve all active subscriptions for a user.
 */
export const getSubscriptions = async (userId: string): Promise<Subscription[]> => {
  const { data, error } = await supabase.from<Subscription>('subscriptions').select().match({ user_id: userId });

  if (error) {
    console.error('Error fetching subscriptions', error);
    return [];
  }
  if (!data) {
    console.warn('Empty data from subscriptions fetch');
    return [];
  }

  return data;
};

export const updateSubscription = async (
  subscriptionId: string,
  updates: Partial<Subscription>
): Promise<Subscription> => {
  const { data, error } = await supabase
    .from<Subscription>('subscriptions')
    .update(updates)
    .match({ id: subscriptionId });

  if (error) {
    console.error('Error updating subscription', error);
    throw error;
  }
  if (!data || !data[0]) {
    console.error('Nothing returned from subscription update call');
    throw new Error('Empty update subscription response');
  }

  return data[0];
};

/**
 * ARTICLES
 */

export const getArticle = async (articleId: string, userId: string): Promise<Article | undefined> => {
  const { data, error } = await supabase.from<Article>('articles').select().match({ id: articleId, user_id: userId });

  if (error) {
    console.error('Error fetching article', articleId, error);
    return undefined;
  }
  if (!data || data.length === 0) {
    console.warn('Article not found', articleId);
    return undefined;
  }

  return data[0];
};

/**
 * Fetches a user's article queue for the provided inbox type.
 *
 * @param userId The user ID.
 * @param inbox The inbox type.
 */
export const getArticles = async (userId: string, inbox: Inbox): Promise<Article[]> => {
  let filters: Partial<Article> = { user_id: userId };
  let orderField: keyof Article = 'created_at';
  let orderAscending = false;

  switch (inbox) {
    case Inbox.archive:
      filters = { ...filters, archived: true };
      break;
    case Inbox.triage:
      filters = { ...filters, triaged: false, archived: false };
      break;
    case Inbox.queue:
      filters = { ...filters, archived: false, triaged: true };
      orderField = 'updated_at';
      orderAscending = true;
      break;
  }

  const { data, error } = await supabase
    .from<Article>('articles')
    .select()
    .match((filters as unknown) as { [key: string]: string }) // Supabase TS bug.
    .order(orderField, { ascending: orderAscending });

  if (error) {
    console.error('Error fetching articles', error);
    return [];
  }
  if (!data) {
    console.warn('Empty data from article fetch');
    return [];
  }

  return data;
};

export const getSubscriptionArticles = async (
  userId: string,
  subscriptionId: string,
  read: boolean,
  offset = 0
): Promise<Article[]> => {
  const pageSize = 50;

  const filters: Partial<Article> = {
    user_id: userId,
    subscription_id: subscriptionId,
    archived: read ? true : false,
  };

  const { data, error } = await supabase
    .from<Article>('articles')
    .select()
    .match((filters as unknown) as { [key: string]: string })
    .order('created_at', { ascending: false })
    .range(offset, offset + pageSize);

  if (error) {
    console.error('Error fetching subscription articles', error);
    return [];
  }
  if (!data) {
    console.warn('Empty data from subscription article fetch');
    return [];
  }

  return data;
};

export const getSubscriptionUnreadCount = async (userId: string, subscriptionId: string): Promise<number> => {
  const filters: Partial<Article> = { subscription_id: subscriptionId, user_id: userId, archived: false };

  const { count, error } = await supabase
    .from<Article>('articles')
    .select('id', { count: 'exact' })
    .match((filters as unknown) as { [key: string]: string });

  if (error) {
    console.error('Error fetching subscription unread count', error);
    return 0;
  }
  if (count === null) {
    console.error('Subscription unread count is null');
    return 0;
  }

  return count;
};

export const getArticleCount = async (userId: string, filters: Partial<Article>): Promise<number> => {
  filters.user_id = userId;

  const { count, error } = await supabase
    .from<Article>('articles')
    .select('id', { count: 'exact' })
    .match((filters as unknown) as { [key: string]: string });

  if (error) {
    console.error('Error fetching article unread count', error);
    return 0;
  }
  if (count === null) {
    console.error('Article unread count is null');
    return 0;
  }

  return count;
};

/**
 * Persist an updated article in the database.
 *
 * @param articleId The article ID.
 * @param updates The updated partial or complete article.
 */
export const updateArticle = async (articleId: string, updates: Partial<Article>): Promise<Article> => {
  const { data, error } = await supabase.from<Article>('articles').update(updates).match({ id: articleId });

  if (error) {
    console.error('Error updating article', error);
    throw error;
  }
  if (!data || !data[0]) {
    console.error('Nothing returned from article update call');
    throw new Error('Empty update article response');
  }

  return data[0];
};

/**
 * CATEGORIES
 */

/**
 * Fetch the user's list of categories.
 *
 * @param userId The user ID.
 */
export const getCategories = async (userId: string): Promise<Category[]> => {
  const { data, error } = await supabase.from<Category>('categories').select().match({ user_id: userId });

  if (error) {
    console.error('Error fetching categories', error);
    return [];
  }
  if (!data) {
    console.warn('Empty data from categories fetch');
    return [];
  }

  return data;
};

/**
 * Create a new category for the given user.
 * This also sets an emoji if provided or falls back to random generation.
 *
 * @param userId The user ID.
 * @param name  The category name.
 */
export const createCategory = async (userId: string, name: string, emoji?: string): Promise<Category> => {
  const category: Partial<Category> = {
    user_id: userId,
    name,
    slug: toSlug(name),
    emoji: emoji ? emoji : getRandomEmoji(),
  };

  const { data, error } = await supabase.from<Category>('categories').insert(category);

  if (error) {
    console.error('Error inserting category', error);
    throw error;
  }
  if (!data || !data[0]) {
    console.error('Nothing returned from category insert call');
    throw new Error('Empty insert category response');
  }

  return data[0];
};

/**
 * Persist an updated category to the database.
 */
export const updateCategory = async (id: string, userId: string, updates: Partial<Category>): Promise<Category> => {
  const { data, error } = await supabase
    .from<Category>('categories')
    .update(updates)
    .match({ id: id, user_id: userId });

  if (error) {
    console.error('Error updating category', error);
    throw error;
  }
  if (!data || !data[0]) {
    console.error('Nothing returned from category update call');
    throw new Error('Empty update category response');
  }

  return data[0];
};

/**
 * HELPERS
 */

const hashPassword = async (password: string): Promise<string> => bcrypt.hash(password, BCRYPT_SALT_ROUNDS);
