import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Article, Subscription } from '../../api/types';
import { useAppSelector } from '../../hooks/useState';
import loadingAnimation from '../../img/loading.gif';
import { setFlowMode, triageArticle } from '../../state/newsletters';
import { CardSize } from '../../state/newsletters';
import { AppState } from '../../store';
import InboxNavbar from '../navigation/InboxNavbar';
import Newsletter from '../subscription/Newsletter';
import SmallNewsletter from '../subscription/SmallNewsletter';
import { FilterOption } from '../ui/FilterToggle';
import InboxCard from './InboxCard';
import InboxZero from './InboxZero';
import WelcomeCard from './WelcomeCard';

const TRIAGE = 'triage';
const GRID = 'grid';

const FILTERS: FilterOption[] = [
  {
    id: TRIAGE,
    name: 'Flow',
  },
  {
    id: GRID,
    name: 'All',
  },
];

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CountLabel = styled.p`
  color: #b1b1b1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const Inbox: React.FC = () => {
  const dispatch = useDispatch();

  const cardSize = useAppSelector((state) => state.newsletters.previewSize);
  const loadDone = useAppSelector((state) => state.users.articlesLoadDone);
  const flowMode = useAppSelector((state) => state.newsletters.flowMode);

  // Triage queue.
  const unreadQueue = useSelector<AppState, Article[]>((state) => {
    return state.newsletters.inboxes.triage.articleIds.map((articleId) => state.newsletters.articles[articleId]);
  });

  // Check number of subscription for onboarding view.
  const hasSubscriptions = useSelector<AppState, boolean>(
    (state) => Object.keys(state.newsletters.subscriptions).length > 0
  );

  const subscriptions = useSelector<AppState, Record<string, Subscription>>((state) => {
    const map: { [id: string]: Subscription } = {};
    Object.values(state.newsletters.subscriptions).forEach(({ subscription }) => {
      map[subscription.id] = subscription;
    });

    return map;
  });

  const handleTriage = useCallback(
    (approve, categoryId) => {
      const article = unreadQueue[0];
      if (article) {
        dispatch(triageArticle(article, approve, categoryId));
      }
    },
    [unreadQueue, dispatch]
  );

  const handleFilter = useCallback(
    (filter: string) => {
      const isFlow = filter === TRIAGE;
      dispatch(setFlowMode(isFlow));
    },
    [dispatch]
  );

  // The current article, or undefined if the queue is empty.
  const article = unreadQueue[0];

  if (!loadDone) {
    console.log('loading');
    return (
      <Centered className="mt-20">
        <img className="w-20" src={loadingAnimation} alt="loading" />
        <p className="mt-30 font-medium text-2xl">Getting ready to flow...</p>
      </Centered>
    );
  }

  return (
    <>
      <InboxNavbar
        title="All Unread"
        filterOptions={FILTERS}
        selectedFilter={flowMode ? TRIAGE : GRID}
        onChangeFilter={handleFilter}
      />

      <div className="w-full">
        {hasSubscriptions ? (
          unreadQueue.length > 0 ? (
            <>
              {flowMode ? (
                <Centered>
                  <CountLabel className="pt-5">
                    {`${unreadQueue.length} in your queue `}
                    <span role="img" aria-label="">
                      ✨
                    </span>
                  </CountLabel>
                  <InboxCard article={article} swipe={handleTriage} />
                </Centered>
              ) : (
                <>
                  <div className="flex flex-col py-8 px-8">
                    {cardSize === CardSize.large ? (
                      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
                        {unreadQueue.map((article, idx) => (
                          <Newsletter
                            key={article.id}
                            article={article}
                            subscription={subscriptions[article.subscription_id]}
                            fallbackImageIdx={idx}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="border rounded-md">
                        {unreadQueue.map((article) => (
                          <SmallNewsletter
                            key={article.id}
                            article={article}
                            subscription={subscriptions[article.subscription_id]}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <Centered>
              <InboxZero />
            </Centered>
          )
        ) : (
          <Centered style={{ paddingTop: 80 }}>
            <WelcomeCard />
          </Centered>
        )}
      </div>
    </>
  );
};

export default Inbox;
