import React, { memo, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useAppSelector } from '../../hooks/useState';
import send from '../../img/new/send.svg';
import Modal from '../ui/Modal';

interface EmailForwardingModalProps {
  showing: boolean;
  onDismiss: () => void;
}

const EmailForwardingModal: React.FC<EmailForwardingModalProps> = ({ showing, onDismiss }) => {
  const { addToast } = useToasts();
  const user = useAppSelector((state) => state.users.profile);

  const copyCode = useCallback(() => {
    if (!user || !user.confirmation_code) {
      return;
    }

    navigator.clipboard.writeText(user.confirmation_code);
    const options = {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 1500,
    };

    // @ts-expect-error The types for the addToast options payload are incorrect. See: https://github.com/jossmac/react-toast-notifications.
    addToast('Copied to clipboard!', options);
  }, [user, addToast]);

  return (
    <Modal showing={showing} onDismiss={onDismiss}>
      <div className="p-8">
        <div className="font-bold text-xl">Gmail forwarding</div>
        <div className="flex h-screen">
          <div className="text-gray-700 text-base p-8">
            {user && user.confirmation_code ? (
              <div>
                <div className="px-8">
                  <div className="flex flex-col mb-4">
                    <p className="text-gray-500 text-base">
                      Click to copy the following confirmation code under Gmail settings -&gt; Forwarding and POP/IMPAP{' '}
                    </p>
                  </div>

                  <div className="bg-gray-100 rounded-lg">
                    <div className="py-4 px-4">
                      <div
                        onClick={copyCode}
                        onKeyPress={copyCode}
                        role="button"
                        tabIndex={0}
                        className="flex flex-col"
                      >
                        <h4 className="text-2xl font-semibold mb-2 text-center">{user.confirmation_code}</h4>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4">Please email us at hello@flowbox.co if you are having issues confirming</p>
                  <div className="pt-3">
                    <a
                      href="https://mail.google.com/mail/#settings/fwdandpop"
                      className="block text-center shadow bg-red-300 hover:bg-red-400 font-bold focus:shadow-outline focus:outline-none text-white text-base py-3 px-10 rounded"
                    >
                      Go to gmail settings
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="m-auto">
                <img className="m-auto w-10 fill-current-gray-500" src={send} alt="forwarding" />
                <p className="mt-5">
                  Please set up forwarding in Gmail (
                  <a
                    className="text-red-300"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/set-up-email-forwarding-216e4a94a03b4188996a4d8d0ef0372c"
                  >
                    instructions here
                  </a>
                  ) and check back here for the code!
                  <p className="mt-4">
                    PS: If you want to setup forwarding with another email service, reach us out at hello@flowbox.co
                  </p>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(EmailForwardingModal);
