import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Email from '../components/auth/Email';
import Username from '../components/auth/Username';
import logoIcon from '../img/flowbox-logo.svg';
import { performSignUp } from '../state/users';
import { AppState } from '../store';

/**
 * The main sign up view. It displays two states.
 */

enum SignUpStep {
  email = 'EMAIL',
  username = 'USERNAME',
}

type AccountData = {
  name: string;
  email: string;
  password: string;
};

const SignUp: React.FC = () => {
  const dispatch = useDispatch();

  const { profile, loading, error } = useSelector((state: AppState) => ({
    profile: state.users.profile,
    ...state.users.signUp,
  }));

  const [step, setStep] = useState<SignUpStep>(SignUpStep.email);
  const [account, setAccount] = useState<AccountData | undefined>();

  useEffect(() => {
    // Redirect to the home view once we have a user.
    if (profile) {
      dispatch(push('/home'));
    }
  }, [dispatch, profile]);

  const onEmailComplete = useCallback((name: string, email: string, password: string) => {
    setAccount({ name, email, password });
    setStep(SignUpStep.username);
  }, []);

  const onCreateAccount = useCallback(
    (username: string) => {
      if (loading || !account) {
        return;
      }

      const { name, email, password } = account;
      dispatch(performSignUp(name, email, username, password));
    },
    [account, dispatch, loading]
  );

  return (
    <div className="flex items-center justify-center h-screen mx-auto max-w-5xl">
      <div className="absolute top-3 left-5">
        <img className="h-12" src={logoIcon} alt="logo" />
      </div>

      {step === SignUpStep.email ? (
        <Email onComplete={onEmailComplete} />
      ) : (
        <Username loading={loading} error={error} onCreateAccount={onCreateAccount} />
      )}
    </div>
  );
};

export default SignUp;
