import React, { memo, useCallback } from 'react';

import { Article } from '../../api/types';
import archiveIcon from '../../img/new/archive-black.svg';
import backArrow from '../../img/new/arrow-left-black.svg';
import queueIcon from '../../img/new/bookmark-black.svg';
import removeIcon from '../../img/new/minus-circle-black.svg';
import backIcon from '../../img/new/skip-back-black.svg';
import skipIcon from '../../img/new/skip-forward-black.svg';
import unreadIcon from '../../img/new/unread-black.svg';

/**
 * Top navbar used on reader page.
 */

interface ReaderNavbarProps {
  article: Article;
  inQueue: boolean;
  onArchive: () => void;
  onMarkUnread: () => void;
  onAddToQueue: () => void;
  onRemoveFromQueue: () => void;
  onSkip: () => void;
  onPrevious: () => void;
  onBack: () => void;
}

const ReaderNavbar: React.FC<ReaderNavbarProps> = ({
  article,
  inQueue,
  onArchive,
  onMarkUnread,
  onBack,
  onSkip,
  onPrevious,
  onAddToQueue,
  onRemoveFromQueue,
}) => {
  const handleReadAction = useCallback(() => {
    if (article.archived) {
      onMarkUnread();
    } else {
      onArchive();
    }
  }, [article.archived, onArchive, onMarkUnread]);

  const handleQueueAction = useCallback(() => {
    if (article.triaged) {
      onRemoveFromQueue();
    } else {
      onAddToQueue();
    }
  }, [article.triaged, onAddToQueue, onRemoveFromQueue]);

  return (
    <div className="sticky left-0 top-0 right-0 flex items-center h-16 px-8 z-10 bg-white border-b">
      <button onClick={onBack} onKeyDown={onBack} className="cursor-pointer focus:outline-none">
        <img src={backArrow} className="w-5 mr-2" alt="back" />
      </button>
      <h1 className="text-xl font-medium truncate pb-0.5 mr-4">{article.title}</h1>

      <div className="flex items-center ml-auto flex-shrink-0">
        {inQueue && (
          <>
            <button
              className="bg-gray-100 hover:bg-red-200 mr-3 h-8 flex items-center px-2 rounded-md"
              onClick={onPrevious}
            >
              <img className="w-4" src={backIcon} alt="skip" />
              <p className="ml-1.5 text-base text-gray-900">Previous</p>
            </button>
            <button
              className="bg-gray-100 hover:bg-red-200 mr-3 h-8 flex items-center px-2 rounded-md"
              onClick={onSkip}
            >
              <img className="w-4" src={skipIcon} alt="skip" />
              <p className="ml-1.5 text-base text-gray-900">Next</p>
            </button>
          </>
        )}

        <button
          className="bg-gray-100 hover:bg-red-200 mr-3 h-8 flex items-center px-2 rounded-md"
          onClick={handleReadAction}
        >
          <img
            className="w-4"
            src={article.archived ? unreadIcon : archiveIcon}
            alt={article.archived ? 'mark unread' : 'archive'}
          />
          <p className="ml-1.5 text-base text-gray-900">{article.archived ? 'Mark Unread' : 'Archive'}</p>
        </button>

        <button
          className="bg-gray-100 hover:bg-red-200 h-8 flex items-center px-2 rounded-md"
          onClick={handleQueueAction}
        >
          <img
            className="w-4"
            src={article.triaged ? removeIcon : queueIcon}
            alt={article.triaged ? 'remove from queue' : 'add to queue'}
          />
          <p className="ml-1.5 text-base text-gray-900">{article.triaged ? 'Remove' : 'Add to Queue'}</p>
        </button>
      </div>
    </div>
  );
};

export default memo(ReaderNavbar);
