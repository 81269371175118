import { goBack, push, replace, RouterActionType } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getArticle } from '../../api/db';
import { Article } from '../../api/types';
import useQuery from '../../hooks/useQuery';
import { markArticleRead, markArticleUnread, markArticleUntriaged, triageArticle } from '../../state/newsletters';
import { AppState } from '../../store';
import { getNextItem, getPreviousItem, scrollToTop } from '../../util/general';
import ReaderNavbar from '../navigation/ReaderNavbar';
import ArticleContent from './ArticleContent';

const ArticleReader: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const query = useQuery();

  const [article, setArticle] = useState<Article | undefined>();

  const userId = useSelector((state: AppState) => state.users.profile?.uid);
  const navAction = useSelector<AppState, RouterActionType>((state) => state.router.action);

  const inQueue = query.get('from') === 'queue';
  const queuedArticleIds = useSelector<AppState, string[]>((state) => state.newsletters.inboxes.queue.articleIds);

  useEffect(() => {
    // Fetch the article.
    if (!userId) {
      return;
    }

    async function fetchData(articleId: string, userId: string) {
      const result = await getArticle(articleId, userId);
      if (result) {
        setArticle(result);
        scrollToTop();
      } else {
        // Assume we can't be here.
        dispatch(replace('/'));
      }
    }

    fetchData(id, userId);
  }, [id, userId, dispatch]);

  const onBack = useCallback(() => {
    // In the queue, we behave differently.
    if (inQueue) {
      dispatch(push('/read'));
      scrollToTop();
      return;
    }

    // If the last thing we did was "PUSH", should be safe to go back.
    // Otherwise, we may not have come from Flowbox, let's nav home.
    if (navAction === 'PUSH') {
      dispatch(goBack());
      scrollToTop();
    } else {
      console.info('History not on Flowbox, navigating to inbox');
      dispatch(push('/'));
      scrollToTop();
    }
  }, [dispatch, navAction, inQueue]);

  const onPrevious = useCallback(() => {
    if (!article) {
      return;
    }

    // If not in queue mode, just use the back function.
    if (!inQueue) {
      onBack();
      return;
    }

    // Navigate to the previous queued article, resetting to the last if needed.
    const previousArticleId = getPreviousItem(article.id, queuedArticleIds);
    if (!previousArticleId || previousArticleId === article.id) {
      // If no articles are present, pop out.
      onBack();
      return;
    }

    dispatch(push(`/reader/${previousArticleId}?from=queue`));
  }, [article, dispatch, inQueue, onBack, queuedArticleIds]);

  const onNext = useCallback(() => {
    if (!article) {
      return;
    }

    // If not in queue mode, just use the back function.
    if (!inQueue) {
      onBack();
      return;
    }

    // Navigate to the next queued article, resetting to the first if needed.
    const nextArticleId = getNextItem(article.id, queuedArticleIds);
    if (!nextArticleId || nextArticleId === article.id) {
      // If no articles are present, pop out.
      onBack();
      return;
    }

    dispatch(push(`/reader/${nextArticleId}?from=queue`));
  }, [inQueue, dispatch, onBack, article, queuedArticleIds]);

  const onArchive = useCallback(async () => {
    if (!article) {
      return;
    }

    dispatch(markArticleRead(article));
    onNext();
  }, [article, dispatch, onNext]);

  const onMarkUnread = useCallback(() => {
    if (!article) {
      return;
    }

    dispatch(markArticleUnread(article));
    // Won't be called from queue mode.
    onBack();
  }, [article, dispatch, onBack]);

  const onAddToQueue = useCallback(() => {
    if (!article) {
      return;
    }

    dispatch(triageArticle(article, true));
    // Won't be called from queue mode.
    onBack();
  }, [article, dispatch, onBack]);

  const onRemoveFromQueue = useCallback(() => {
    if (!article) {
      return;
    }

    dispatch(markArticleUntriaged(article));
    onNext();
  }, [article, dispatch, onNext]);

  if (!article) {
    return <div />;
  }

  return (
    <>
      <ReaderNavbar
        article={article}
        inQueue={inQueue}
        onArchive={onArchive}
        onMarkUnread={onMarkUnread}
        onAddToQueue={onAddToQueue}
        onRemoveFromQueue={onRemoveFromQueue}
        onSkip={onNext}
        onBack={onBack}
        onPrevious={onPrevious}
      />

      <div>
        <ArticleContent article={article} />
      </div>
    </>
  );
};

export default ArticleReader;
