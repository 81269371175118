import React from 'react';

/**
 * Basic error message container.
 */

interface ErrorMessageProps {
  className?: string;
  error: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ className, error }) => {
  return (
    <div className={`bg-red-100 rounded-md ${className ? className : ''}`}>
      <p className="px-6 py-2 text-red-500">{error}</p>
    </div>
  );
};

export default ErrorMessage;
