import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { getUserByEmail } from '../../api/db';
import astronaut from '../../img/Astronaut.png';
import ErrorMessage from '../ui/ErrorMessage';

/**
 * Email form component used in the sign up flow.
 */

interface EmailProps {
  onComplete: (name: string, email: string, password: string) => void;
}

const Email: React.FC<EmailProps> = ({ onComplete }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onNameChange = useCallback(
    ({ target }) => {
      setName(target.value);
    },
    [setName]
  );

  const onEmailChange = useCallback(
    ({ target }) => {
      setEmail(target.value);
    },
    [setEmail]
  );

  const onPasswordChange = useCallback(
    ({ target }) => {
      setPassword(target.value);
    },
    [setPassword]
  );

  const onSubmit = useCallback(
    async (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();

      if (name && email && password.length < 8) {
        setError(`Password must be at least 8 characters`);
        return;
      }

      if (name && email && password.length >= 8) {
        // Check if we have an account already.
        setLoading(true);
        const user = await getUserByEmail(email);
        if (user) {
          setError(`This email already has an account`);
          setLoading(false);
        } else {
          onComplete(name, email, password);
        }
      }
    },
    [name, email, password, onComplete]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Attempt to submit if enter key.
      if (event.keyCode === 13) {
        event.preventDefault();
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <>
      <div className="hidden lg:flex">
        <img className="w-80" src={astronaut} alt="astronaut" />
      </div>

      <div className="mx-8 flex flex-col w-80">
        <h1 className="text-3xl font-medium">Let&apos;s get you in flow</h1>
        <h3 className="text-lg font-medium mt-5">Create an account</h3>

        <input
          className="focus:border-light-blue-500 bg-gray-50 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full mt-2 max-w-xs text-base text-black placeholder-gray-400 border border-gray-200 rounded-md py-2 px-3"
          type="text"
          autoComplete="name"
          placeholder="Name"
          onChange={onNameChange}
          onKeyDown={onKeyDown}
          value={name}
        />
        <input
          className="focus:border-light-blue-500 bg-gray-50 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full mt-2 max-w-xs text-base text-black placeholder-gray-400 border border-gray-200 rounded-md py-2 px-3"
          type="text"
          autoComplete="email"
          placeholder="Email"
          onChange={onEmailChange}
          onKeyDown={onKeyDown}
          value={email}
        />
        <input
          className="focus:border-light-blue-500 bg-gray-50 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full mt-2 max-w-xs text-base text-black placeholder-gray-400 border border-gray-200 rounded-md py-2 px-3"
          type="password"
          placeholder="Password"
          onChange={onPasswordChange}
          onKeyDown={onKeyDown}
          value={password}
        />

        {error && <ErrorMessage className="mt-5 text-center" error={error} />}

        <button
          className={`bg-pink rounded-md text-white px-8 py-2 font-semibold disabled:opacity-70 focus:outline-none ${
            error ? 'mt-3' : 'mt-8'
          } `}
          disabled={loading}
          onClick={onSubmit}
        >
          Sign up
        </button>
        <p className="mt-4 text-sm text-gray-500">
          Already a member?{' '}
          <Link to="/signin">
            <span className="font-medium text-pink hover:underline">Log in here</span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default Email;
