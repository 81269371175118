import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getSubscriptionArticles } from '../../api/db';
import { Article, Profile, Subscription } from '../../api/types';
import usePrevious from '../../hooks/usePrevious';
import { useAppSelector } from '../../hooks/useState';
import { CardSize, setSubscriptionStarred } from '../../state/newsletters';
import { AppState } from '../../store';
import SubscriptionNavbar from '../navigation/SubscriptionNavbar';
import { FilterOption } from '../ui/FilterToggle';
import Newsletter from './Newsletter';
import SmallNewsletter from './SmallNewsletter';

const READ = 'read';
const UNREAD = 'unread';

const FILTERS: FilterOption[] = [
  {
    id: UNREAD,
    name: 'Unread',
  },
  {
    id: READ,
    name: 'Archived',
  },
];

const Page: React.FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const [unreadArticles, setUnreadArticles] = useState<Article[]>([]);
  const [readArticles, setReadArticles] = useState<Article[]>([]);

  const [showingUnread, setShowingUnread] = useState(true);
  const [loading, setLoading] = useState(true);

  const previousSubscriptionId = usePrevious(params.id);
  const cardSize = useAppSelector((state) => state.newsletters.previewSize);

  const subscription = useSelector<AppState, Subscription | undefined>((state) => {
    return state.newsletters.subscriptions[params.id]?.subscription;
  });
  const user = useSelector<AppState, Profile | undefined>((state) => {
    return state.users.profile;
  });

  useEffect(() => {
    // When we change subscriptions, clear the lists.
    if (previousSubscriptionId !== params.id) {
      setUnreadArticles([]);
      setReadArticles([]);
      setShowingUnread(true);
    }
  }, [previousSubscriptionId, params.id]);

  useEffect(() => {
    if (!params.id || !user) {
      return;
    }

    async function fetchArticles(userId: string, subscriptionId: string) {
      const articles = await getSubscriptionArticles(userId, subscriptionId, false, 0);
      setUnreadArticles(articles);
      setLoading(false);
    }
    fetchArticles(user.uid, params.id);
  }, [user, params.id]);

  const fetchArchive = useCallback(async () => {
    if (!params.id || !user) {
      console.warn(`Can't fetch subscription archive because subscription or user is not defined`);
      return;
    }

    const articles = await getSubscriptionArticles(user.uid, params.id, true, 0);
    setReadArticles(articles);
  }, [user, params.id]);

  const handleFilter = useCallback(
    (filterId: string) => {
      setShowingUnread(filterId === UNREAD);
      if (filterId === READ) {
        // Refresh the archive.
        fetchArchive();
      }
    },
    [fetchArchive]
  );

  const toggleStarred = useCallback(() => {
    if (!subscription) {
      return;
    }

    if (subscription.starred) {
      dispatch(setSubscriptionStarred(subscription, false));
    } else {
      dispatch(setSubscriptionStarred(subscription, true));
    }
  }, [subscription, dispatch]);

  if (loading || !subscription) {
    return <div />;
  }

  const articles = showingUnread ? unreadArticles : readArticles;

  return (
    <>
      <SubscriptionNavbar
        subscription={subscription}
        filterOptions={FILTERS}
        selectedFilter={showingUnread ? UNREAD : READ}
        onChangeFilter={handleFilter}
        onToggleStarred={toggleStarred}
      />

      {articles.length > 0 ? (
        <div className="flex flex-col py-8 px-8">
          {cardSize === CardSize.large ? (
            <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
              {articles.map((article, idx) => (
                <Newsletter key={article.id} article={article} subscription={subscription} fallbackImageIdx={idx} />
              ))}
            </div>
          ) : (
            <div className="border rounded-md">
              {articles.map((article) => (
                <SmallNewsletter key={article.id} article={article} subscription={subscription} />
              ))}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Page;
