import cheerio from 'cheerio';
import React, { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { SummaryContent } from '../../hooks/useSummary';

interface ArticlePreviewProps {
  content?: SummaryContent;
  skipSummary?: boolean;
}

const Container = styled.div`
  height: 45vh;
  overflow-y: auto;
  padding: 0 16px;
`;

const contentDivId = 'content';

const ArticlePreview: React.FC<ArticlePreviewProps> = ({ content }) => {
  const scrollToTop = useCallback(() => {
    const div = document.getElementById(contentDivId);
    if (div) {
      div.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    // Scroll to top when content changes.
    scrollToTop();
  }, [content, scrollToTop]);

  if (!content) {
    return <div />;
  }

  const $ = cheerio.load(content.body);
  $('a').each((idx, element) => {
    $(element).attr('target', '_blank');
  });

  return (
    <Container id={contentDivId}>
      <div className="mt-4" dangerouslySetInnerHTML={{ __html: $.html() }} />
    </Container>
  );
};

export default memo(ArticlePreview);
