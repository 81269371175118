/* Users on the Flowbox platform. */
export interface Profile {
  uid: string;
  name: string;
  email: string;
  password: string;
  username?: string;
  mailbox_token: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  confirmation_code?: string;
}

/* A newsletter subscription, e.g. rambles.substack.com. */
export interface Subscription {
  id: string;
  user_id: string;
  url: string;
  name?: string;
  image_url?: string;
  unsubscribe_url?: string;
  starred: boolean;
  created_at: Date;
  updated_at: Date;
  last_read_at?: Date;
  default_tag?: string;
}

/* An individual article from a subscription. */
export interface Article {
  id: string;
  user_id: string;
  subscription_id: string;
  title?: string;
  subheader?: string;
  issue_url?: string;
  image_url?: string;
  content: string;
  raw_content: string;
  triaged: boolean;
  completed: boolean;
  archived: boolean;
  created_at: Date;
  updated_at: Date;
  archived_at?: Date;
  tags?: string[];
  skip_summary: boolean;
}

/** A tag, or category inbox created by a user. */
export interface Category {
  id: string;
  user_id: string;
  slug: string;
  name: string;
  emoji: string;
  created_at: Date;
  updated_at: Date;
  starred: boolean;
}

/** Local types used in application state */

export interface InboxState {
  articleIds: string[];
  count: number;
}

export interface SubscriptionState {
  subscription: Subscription;
  unread: number;
}

export enum Inbox {
  triage = 'triage',
  queue = 'queue',
  archive = 'archive',
}
