import React, { memo } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import menuIcon from '../../img/new/ellipses-menu.svg';
import FilterToggle, { FilterOption } from '../ui/FilterToggle';
import NavMenu from './NavMenu';

interface InboxNavbarProps {
  filterOptions: FilterOption[];
  selectedFilter: string;
  title: string;
  onChangeFilter: (filter: string) => void;
}

const InboxNavbar: React.FC<InboxNavbarProps> = ({ filterOptions, selectedFilter, title, onChangeFilter }) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    placement: 'top-end',
  });

  return (
    <>
      <div className="sticky left-0 top-0 right-0 flex items-center h-16 px-8 py-4 z-10 bg-white border-b">
        <h1 className="text-xl font-medium">{title}</h1>

        <div className="ml-auto flex items-center">
          <FilterToggle className="mr-4" options={filterOptions} selected={selectedFilter} onSelect={onChangeFilter} />

          <button
            className="hover:border bg-gray-50 hover:bg-gray-100 ml-auto h-8 flex items-center px-2 rounded-md focus:outline-none"
            ref={setTriggerRef}
          >
            <img className="w-5" src={menuIcon} alt="menu" />
          </button>
        </div>
      </div>

      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container z-30 -mt-0.5 p-0' })}>
          <NavMenu />
        </div>
      )}
    </>
  );
};

export default memo(InboxNavbar);
