import React, { memo, useCallback } from 'react';

export type FilterOption = { id: string; name: string };

interface FilterToggleProps {
  className?: string;
  options: FilterOption[];
  selected: string;
  onSelect: (id: string) => void;
}

const FilterToggle: React.FC<FilterToggleProps> = ({ className, options, selected, onSelect }) => {
  const handleSelect = useCallback(
    (selectionId: string) => {
      // Only pass the selection to the parent if it's not the currently selected option.
      if (selectionId !== selected) {
        onSelect(selectionId);
      }
    },
    [selected, onSelect]
  );

  const renderOption = useCallback(
    (option: FilterOption, isSelected: boolean) => {
      return (
        <div
          className={`px-3 py-1.5 rounded-md cursor-pointer focus:outline-none ${isSelected ? 'bg-gray-100' : ''}`}
          key={option.id}
          role="button"
          onClick={() => handleSelect(option.id)}
          onKeyUp={() => handleSelect(option.id)}
          tabIndex={0}
        >
          <p className={`text-sm font-medium pb-0.5 m-0`}>{option.name}</p>
        </div>
      );
    },
    [handleSelect]
  );

  return (
    <div className={`flex items-center ${className}`}>
      {options.map((option) => renderOption(option, option.id === selected))}
    </div>
  );
};

export default memo(FilterToggle);
