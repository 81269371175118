import React, { memo } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { Subscription } from '../../api/types';
import menuIcon from '../../img/new/ellipses-menu.svg';
import emptyStarIcon from '../../img/new/star-empty.svg';
import filledStarIcon from '../../img/new/star-filled-pink.svg';
import FilterToggle, { FilterOption } from '../ui/FilterToggle';
import NavMenu from './NavMenu';

/**
 * Navbar used on the individual subscription page.
 */

interface SubscriptionNavbarProps {
  filterOptions: FilterOption[];
  selectedFilter: string;
  subscription: Subscription;
  onChangeFilter: (filter: string) => void;
  onToggleStarred: () => void;
}

const SubscriptionNavbar: React.FC<SubscriptionNavbarProps> = ({
  subscription,
  filterOptions,
  selectedFilter,
  onChangeFilter,
  onToggleStarred,
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    placement: 'top-end',
  });

  return (
    <>
      <div className="sticky left-0 top-0 right-0 flex items-center h-16 px-8 z-10 bg-white border-b">
        <div className="flex flex-col">
          <div className="flex items-center">
            <h1 className="text-xl font-medium">{subscription?.name || 'Subscription'}</h1>
            <button className="cursor-pointer ml-2 focus:outline-none" onClick={onToggleStarred}>
              <img
                className="w-4"
                src={subscription.starred ? filledStarIcon : emptyStarIcon}
                alt={subscription.starred ? 'Unstar' : 'Star'}
              />
            </button>
          </div>
          {subscription?.url ? (
            <p className="text-sm font-medium -mt-1 text-gray-500">
              <a className="hover:underline" href={`https://${subscription.url}`} target="_blank" rel="noreferrer">
                {subscription.url}
              </a>
            </p>
          ) : null}
        </div>

        <div className="ml-auto flex items-center">
          <FilterToggle className="mr-4" options={filterOptions} selected={selectedFilter} onSelect={onChangeFilter} />

          <button
            className="hover:border bg-gray-50 hover:bg-gray-100 ml-auto h-8 flex items-center px-2 rounded-md focus:outline-none"
            ref={setTriggerRef}
          >
            <img className="w-5" src={menuIcon} alt="menu" />
          </button>
        </div>
      </div>

      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container z-30 -mt-0.5 p-0' })}>
          <NavMenu />
        </div>
      )}
    </>
  );
};

export default memo(SubscriptionNavbar);
