import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Article } from '../../api/types';
import { useAppSelector } from '../../hooks/useState';
import useSummary from '../../hooks/useSummary';
import substackLogo from '../../img/substack.png';
import thumbsDown from '../../img/thumbs-down.png';
import thumbsUp from '../../img/thumbs-up.png';
import ArticlePreview from './ArticlePreview';

interface InboxCardProps {
  article: Article;
  swipe: (approve: boolean, categoryId?: string) => void;
}

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px #e5e7eb;
  border-radius: 10px;
  border: 0px solid #e5e7eb;
  padding: 16px 0;
  max-width: 700px;
  width: 75%;
  transition: box-shadow 0.25s ease-in-out;
  max-height: 75vh;

  &:hover {
    box-shadow: 0px 0px 10px 5px #e5e7eb;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0px solid #e5e7eb;
  padding: 0 16px 12px 16px;
  box-siding: border-box;
`;

const HeaderImage = styled.img`
  height: 32px;
  width: 32px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const SubscriptionTitle = styled.p`
  color: #f4b1af;
  font-size: 16px;
  line-height: 19px;
`;

const IssueTitle = styled.p`
  color: #101010;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

const IssueContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
`;

const SwipeButtons = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  border-top: 0px solid #e5e7eb;
  width: 100%;
  padding-top: 16px;

  > div {
    display: flex;
  }
`;

const SwipeButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &:first-of-type {
    margin-right: 12px;
  }

  > img {
    width: 16px;
    height: 16px;
  }
`;

const NoButton = styled(SwipeButton)`
  background-color: #fff;
  border: 2px solid #f4b1af;
  flex-direction: row;
`;

const YesButton = styled(SwipeButton)`
  background-color: #f4b1af;
  flex-direction: row;
`;

const defaultSubscriptionText = 'Substack subscription';

const InboxCard: React.FC<InboxCardProps> = ({ article, swipe }) => {
  const subscription = useAppSelector(
    (state) => state.newsletters.subscriptions[article.subscription_id]?.subscription
  );

  const content = useSummary(article.content, article.skip_summary);

  const handleYes = useCallback(() => {
    swipe(true);
  }, [swipe]);

  const handleNo = useCallback(() => {
    swipe(false);
  }, [swipe]);

  const title = useMemo(() => {
    if (article.title) {
      return article.title;
    }
    if (content) {
      return content.headline || content.preview;
    }
    return 'Issue';
  }, [article, content]);

  return (
    <Container>
      <Header>
        <HeaderImage src={substackLogo} />
        <HeaderText>
          <SubscriptionTitle>
            {subscription ? subscription.name || defaultSubscriptionText : defaultSubscriptionText}
          </SubscriptionTitle>
          <IssueTitle>{title}</IssueTitle>
        </HeaderText>
      </Header>

      <IssueContent>
        <ArticlePreview content={content} skipSummary={article.skip_summary} />
        <SwipeButtons>
          <div>
            <NoButton onClick={handleNo}>
              <img src={thumbsDown} alt="no" />
            </NoButton>
            <YesButton onClick={handleYes}>
              <img src={thumbsUp} alt="yes" />
            </YesButton>
          </div>
        </SwipeButtons>
      </IssueContent>
    </Container>
  );
};

export default InboxCard;
