import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import animation from '../../img/reading_girl.gif';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 200px;
  margin-bottom: 40px;
`;

const Title = styled.p`
  color: #101010;
  font-size: 30px;
  margin-top: 50px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 30px;
  text-align: center;
`;

const InboxZero: React.FC = () => {
  return (
    <Container>
      <Title>Done! time to read</Title>
      <Image src={animation} />

      <Link to="/read">
        <button className="bg-pink-500 hover:bg-pink-700 text-black font-regular py-2 px-4 rounded-full">
          Go to Reading Queue
        </button>
      </Link>
    </Container>
  );
};

export default InboxZero;
